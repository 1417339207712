import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SmartListItem.scss';

const SmartListItem = ({ className, label, value, extraValue }) => {
  const { row, columnLeft, columnRight, extraOption, columnIcon, columnData } = styles;

  return (
    <div className={row}>
      <div
        className={classnames({
          [`${columnLeft}`]: !className,
          [`${columnIcon}`]: className
        })}
      >
        {label}
      </div>
      <div
        className={classnames({
          [`${columnRight}`]: !className,
          [`${columnData}`]: className
        })}
      >
        <div>{value}</div>
        {extraValue && <div className={extraOption}>{extraValue}</div>}
      </div>
    </div>
  );
};

SmartListItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]),
  extraValue: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

export default SmartListItem;
