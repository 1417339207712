import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SmartStudentReportCoursesTable from './Desktop/SmartStudentReportCoursesTable';
import SmartStudentReportCoursesTableMobile from './Mobile/SmartStudentReportCoursesTableMobile';
import useMediaQuery from '../../../utils/mediaQuery';
import classnames from 'classnames';
import styles from './SmartStudentReportCoursesTableContainer.scss';
import TablePagination from '../../TablePagination/TablePagination';

const SmartStudentReportCoursesTableContainer = ({
  items,
  firstColumn,
  isFixedWidth,
  handleItemClick,
  handleBackToTopClick
}) => {
  const [sortElement, setSortElement] = useState('name');
  const [sortDir, setSortDir] = useState('asc');
  const [records, setRecords] = useState([items]);
  const [currentPage, setCurrentPage] = useState(1);

  const isMobile = useMediaQuery('(max-width: 1023px)');

  const handleSortAction = key => {
    if (key !== sortElement) {
      setSortElement(key);
      setSortDir('asc');
      return;
    }

    setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
  };

  // #region Sorting
  const SORT_DATA_HANDLERS = {
    name: (a, b) => (a.name > b.name && 1) || -1,
    questionsAnswered: (a, b) => (a.questionsAnswered > b.questionsAnswered && 1) || -1,
    memoryStrength: (a, b) => (a.memoryStrength > b.memoryStrength && 1) || -1,
    developing: (a, b) => (a.developing > b.developing && 1) || -1,
    secure: (a, b) => (a.secure > b.secure && 1) || -1,
    extending: (a, b) => (a.extending > b.extending && 1) || -1
  };

  const sortData = data => {
    const sortHandler = SORT_DATA_HANDLERS[sortElement];
    if (sortHandler === undefined) return data;
    const sortOrderModifier = sortDir === 'asc' ? 1 : -1;
    return data.sort((a, b) => sortOrderModifier * sortHandler(a, b, sortOrderModifier));
  };
  // #endregion
  const paginateData = data => data;
  useEffect(() => {
    const sortedItems = sortData([...items]);
    const paginatedData = paginateData(sortedItems);

    setRecords(paginatedData);
  }, [sortElement, sortDir, items]);

  const handlePageChangedAction = page => {
    setCurrentPage(page);
  };

  const onClickBackToTopHandler = () => {
    handleBackToTopClick();
  };

  const renderTable = () =>
    isMobile ? (
      <SmartStudentReportCoursesTableMobile items={records} handleItemClick={handleItemClick} />
    ) : (
      <SmartStudentReportCoursesTable
        items={records}
        firstColumn={firstColumn}
        handleSortAction={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
        handleItemClick={handleItemClick}
      />
    );

  const renderPagination = () => (
    <TablePagination
      onPageChangedHandler={handlePageChangedAction}
      totalNumberOfEntries={items.length}
      page={currentPage}
      rowsPerPage={items.length}
      onClickBackToTopHandler={onClickBackToTopHandler}
    />
  );

  return (
    <div className={styles.tableWrapper}>
      <div
        className={classnames({
          [`${styles.tableContainerFixedWidth}`]: isFixedWidth,
          [`${styles.tableContainerFullWidth}`]: !isFixedWidth
        })}
      >
        {renderTable()}
        {records.length !== 0 && renderPagination()}
      </div>
    </div>
  );
};

SmartStudentReportCoursesTableContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  firstColumn: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  isFixedWidth: PropTypes.bool,
  handleItemClick: PropTypes.func,
  handleBackToTopClick: PropTypes.func
};

SmartStudentReportCoursesTableContainer.defaultProps = {
  isFixedWidth: true
};

export default SmartStudentReportCoursesTableContainer;
