import React from 'react';

const SmartDemoQuad = () => (
  <>
    <div style={{ textAlign: 'center' }}>
      <h1>Demo Quad Page - for testing purposes only</h1>
    </div>
  </>
);

export default SmartDemoQuad;
