import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SmartOverlayPanel from '../../../SmartOverlayPanel/SmartOverlayPanel';
import SmartContent from '../../../SmartContent/SmartContent';
import { GLYPHS } from '../../../SVGIcon/SVGIcon';
import SmartListItem from '../../../SmartList/SmartListItem/SmartListItem';
import SmartCard from '../../../SmartCard/SmartCard';
import styles from './SmartOverlayPanelTableManuallyNonInteractive.scss';
import { hasValueNotApplicable } from '../../../../utils/string';
import SmartOverlayPanelQuestions from '../SmartOverlayPanelQuestions/SmartOverlayPanelQuestions';
import { canRenderDseBand, renderDseBand, renderMarkRow } from '../utils';

const SmartOverlayPanelTableManuallyNonInteractive = ({ isOpen, handleClose, animation, manuallyMarkedNI }) => {
  const { wrapper, sectionTitle, wrapperLast } = styles;

  const [areAnswersOpen, setAreAnswersOpen] = useState(false);

  useEffect(() => () => setAreAnswersOpen(false), [isOpen]);

  if (!manuallyMarkedNI) {
    return <></>;
  }

  const { markLabel, percentageLabel, dseLabel, sidePanelInfo } = manuallyMarkedNI;

  return (
    <SmartOverlayPanel isOpen={isOpen} handleClose={handleClose} animation={animation}>
      <SmartContent
        contentOverData={{
          title: `[${manuallyMarkedNI.task}]`,
          iconRight: GLYPHS.ICON_CLOSE
        }}
        contentUnderData={{
          content: (
            <>
              <SmartCard noPadding hasBorder>
                <div className={wrapper}>
                  <div className={sectionTitle}>Results</div>
                  {canRenderDseBand(dseLabel) && <SmartListItem label="DSE band:" value={renderDseBand(dseLabel)} />}
                  {!hasValueNotApplicable(markLabel) && (
                    <SmartListItem
                      label="Mark:"
                      value={renderMarkRow(markLabel, percentageLabel)}
                      extraValue={
                        sidePanelInfo.questions.length > 0 && (
                          <a onClick={() => setAreAnswersOpen(!areAnswersOpen)} aria-hidden="true">
                            {areAnswersOpen ? 'Hide your answers' : 'Review your answers'}
                          </a>
                        )
                      }
                    />
                  )}
                  {areAnswersOpen && <SmartOverlayPanelQuestions questions={sidePanelInfo.questions} />}
                </div>
              </SmartCard>
              <SmartCard noPadding>
                <div className={wrapperLast}>
                  <div className={sectionTitle}>About</div>
                  <SmartListItem label="Content type:" value={manuallyMarkedNI.contentType} />
                  <SmartListItem label="Assigned by:" value={manuallyMarkedNI.assignedBy} />
                  <SmartListItem
                    label="Assignment:"
                    value={
                      <a href={manuallyMarkedNI.sidePanelInfo.contentAssignment.url}>
                        {manuallyMarkedNI.sidePanelInfo.contentAssignment.name}
                      </a>
                    }
                  />
                  <SmartListItem
                    label="Support:"
                    value={
                      <a href={manuallyMarkedNI.sidePanelInfo.contentSupport.url}>
                        {manuallyMarkedNI.sidePanelInfo.contentSupport.name}
                      </a>
                    }
                  />
                  <SmartListItem
                    label=""
                    value={
                      <a href={manuallyMarkedNI.sidePanelInfo.contentSupport.url}>
                        {manuallyMarkedNI.sidePanelInfo.contentSupport.name}
                      </a>
                    }
                  />
                </div>
              </SmartCard>
            </>
          )
        }}
        handleRightBtn={handleClose}
      />
    </SmartOverlayPanel>
  );
};

SmartOverlayPanelTableManuallyNonInteractive.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  animation: PropTypes.oneOf(['left', 'right']),
  manuallyMarkedNI: PropTypes.object
};

export default SmartOverlayPanelTableManuallyNonInteractive;
