import React, { Component } from 'react';
import { PLATFORMS } from '@oup/shared-node-browser/constants';
import logoutRedirect from '../../utils/generic-authorization/generic-logout';
import session from '../../utils/session';
import loginRedirect from '../../utils/generic-authorization/generic-login';

let timer;

// eslint-disable-next-line react/prefer-stateless-function
class DemoLanding extends Component {
  constructor() {
    super();
    this.state = {
      curTime: null
    };
  }

  componentDidMount() {
    timer = setInterval(() => {
      this.setState({
        curTime: new Date().toLocaleString()
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(timer);
  }

  render() {
    // const isLoggedIn = !!session.get().oidc.accessToken;
    const { curTime } = this.state;

    const loginBtn = (
      <button
        type="submit"
        style={{
          padding: '1em',
          borderRadius: '5px',
          background: '#FFD700',
          cursor: 'pointer'
        }}
        onClick={() => loginRedirect(PLATFORMS.SMART)}
      >
        Login
      </button>
    );
    const logoutBtn = (
      <button
        type="submit"
        style={{
          padding: '1em',
          borderRadius: '5px',
          background: '#66C7F4',
          cursor: 'pointer'
        }}
        onClick={logoutRedirect}
      >
        Logout
      </button>
    );

    return (
      <div>
        <div style={{ textAlign: 'center' }}>
          <h1>Demo Landing Page</h1>
        </div>

        <div style={{ textAlign: 'center', marginTop: '5em' }}>
          <p>
            This is a demo page to highlight the Auth0 functionality, it is not complete, usable or intended for
            production!
          </p>
          <div style={{ textAlign: 'center', marginTop: '5em' }} className={curTime}>
            {session.get().oidc.accessToken ? logoutBtn : loginBtn}
          </div>
        </div>
      </div>
    );
  }
}

export default DemoLanding;
