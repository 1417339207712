import React from 'react';
import moment from 'moment';
import SmartOverlayPanelTableLink from './SmartOverlayPanelTableLink/SmartOverlayPanelTableLink';
import SmartOverlayPanelTableMarkRow from '../../../../components/SmartOverlayPanelTableMarkRow/SmartOverlayPanelTableMarkRow';
import SmartBadge from '../../../../components/SmartBadge/SmartBadge';
import SmartListItem from '../../../../components/SmartList/SmartListItem/SmartListItem';
import { hasValueAwaiting, hasValueNotApplicable } from '../../../../utils/string';

export const renderMarkRow = (mark, percentage) => (
  <SmartOverlayPanelTableMarkRow mark={mark} percentage={percentage} />
);

export const renderDseBand = value => <SmartBadge label={value.toUpperCase()} />;

export const canRenderDseBand = label => label && !hasValueAwaiting(label) && !hasValueNotApplicable(label);

/**
 * @todo move to separate component
 */
export const renderSupportLinks = contentSupport =>
  contentSupport.map((support, index) => {
    if (index === 0) {
      return (
        <SmartListItem
          key={index}
          label="Support:"
          value={<SmartOverlayPanelTableLink link={support.url} displayText={support.name} />}
        />
      );
    }

    return (
      <SmartListItem
        key={index}
        label=""
        value={<SmartOverlayPanelTableLink link={support.url} displayText={support.name} />}
      />
    );
  });

export const prettifyDate = date => moment.unix(date).format('DD/M/YY');
