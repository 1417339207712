import React from 'react';
import PropTypes from 'prop-types';
import styles from './TaskInfoArea.scss';
import { hasValueNotApplicable } from '../../../../utils/string';
import { prettifyDate, renderMarkRow } from './utils';
import SmartOverlayPanel from '../../../../components/SmartOverlayPanel/SmartOverlayPanel';
import SmartContent from '../../../../components/SmartContent/SmartContent';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import SmartCard from '../../../../components/SmartCard/SmartCard';
import SmartListItem from '../../../../components/SmartList/SmartListItem/SmartListItem';

function TaskInfoAreaReactivates({ isOpen, handleClose, animation, reactivateItem }) {
  const { wrapper, sectionTitle } = styles;

  if (!reactivateItem) {
    return <></>;
  }

  const { markLabel, percentageLabel, dseLabel, dateCompleted } = reactivateItem;
  return (
    <SmartOverlayPanel isOpen={isOpen} handleClose={handleClose} animation={animation}>
      <SmartContent
        contentOverData={{
          title: `[${reactivateItem.task}]`,
          iconRight: GLYPHS.ICON_CLOSE
        }}
        contentUnderData={{
          content: (
            <SmartCard noPadding hasBorder>
              <div className={wrapper}>
                <div className={sectionTitle}>Results</div>
                <SmartListItem label="Date completed:" value={prettifyDate(dateCompleted)} />
                {!hasValueNotApplicable(markLabel) && (
                  <SmartListItem label="Mark:" value={renderMarkRow(dseLabel, percentageLabel)} />
                )}
              </div>
            </SmartCard>
          )
        }}
        handleRightBtn={handleClose}
      />
    </SmartOverlayPanel>
  );
}

TaskInfoAreaReactivates.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  animation: PropTypes.oneOf(['left', 'right']),
  reactivateItem: PropTypes.object
};

export default TaskInfoAreaReactivates;
