import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartCard.scss';

const SmartCard = ({ children, noPadding, hasBorder, roundedBorder }) => {
  const { smartCard, smartCardApplyBorder } = styles;
  let borderRadius;

  switch (roundedBorder) {
    case 'top':
      borderRadius = '5px 5px 0 0';
      break;
    case 'bottom':
      borderRadius = '0 0 5px 5px';
      break;
    case 'none':
      borderRadius = '0';
      break;
    default:
      borderRadius = '5px';
      break;
  }

  return (
    <>
      <div
        className={`${smartCard} ${hasBorder ? smartCardApplyBorder : ''}`}
        style={{
          padding: `${noPadding ? '0' : '10px'}`,
          borderRadius: `${borderRadius}`
        }}
      >
        {children}
      </div>
    </>
  );
};

SmartCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.string]),
  noPadding: PropTypes.bool,
  hasBorder: PropTypes.bool,
  roundedBorder: PropTypes.oneOf(['none', 'all', 'top', 'bottom'])
};

SmartCard.defaultProps = {
  noPadding: false,
  hasBorder: false,
  roundedBorder: 'all'
};

export default SmartCard;
