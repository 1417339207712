import React from 'react';
import PropTypes from 'prop-types';

function EmptyLayout({ children }) {
  return <div id="app">{children}</div>;
}

EmptyLayout.propTypes = {
  children: PropTypes.any
};

export default EmptyLayout;
