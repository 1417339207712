import React from 'react';
import FiltersAreaMobile from './FiltersAreaMobile';
import FiltersAreaDesktop from './FiltersAreaDesktop';
import useMediaQuery from '../../../../utils/mediaQuery';

function FiltersArea() {
  const isMobile = useMediaQuery('(max-width: 1023px)'); // maybe this can be moved to context

  if (isMobile) return <FiltersAreaMobile />;

  return <FiltersAreaDesktop />;
}
export default FiltersArea;
