import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import USER_ROLES, { roleIsAtLeast } from '../../../globals/userRoles.js';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { productCSSVariables } from '../../NGSCourseLayout/NGSCourseLayout';

import colorLightness from '../../../utils/colorLightness';

import styles from './NGSCourseSubHeader.scss';

import Link from '../../../components/Link/Link';
import SVGIcon, { GLYPHS_NGS } from '../../../components/SVGIcon/SVGIcon.js';

import isRegion from '../../../utils/isRegion';

let STUDENT_LINKS = ['course', 'resources', 'homework', 'marks'];
let TEACHER_LINKS = ['course', 'resources', 'reports'];

if (isRegion('kb')) {
  TEACHER_LINKS = TEACHER_LINKS.filter(route => route !== 'reports');

  STUDENT_LINKS = STUDENT_LINKS.filter(route => route !== 'homework' && route !== 'marks');
}

const LINKS = {
  course: {
    path: '/ngs/courses/{course_id}',
    title_i18n_key: 'ngs_course_homepage_text',
    glyph: GLYPHS_NGS.BOOK
  },
  resources: {
    path: '/ngs/courses/{course_id}/resources',
    title_i18n_key: 'ngs_course_resources_text',
    glyph: GLYPHS_NGS.RESOURCES
  },
  reports: {
    path: '/ngs/courses/{course_id}/reports',
    title_i18n_key: 'ngs_course_reports_text',
    glyph: GLYPHS_NGS.MARKS
  },
  homework: {
    path: '/ngs/courses/{course_id}/homework',
    title_i18n_key: 'ngs_course_homework_text',
    glyph: GLYPHS_NGS.HOMEWORK
  },
  marks: {
    path: '/ngs/courses/{course_id}/marks',
    title_i18n_key: 'ngs_course_marks_text',
    glyph: GLYPHS_NGS.MARKS
  },
  more: {
    path: '#',
    title_i18n_key: 'ngs_more_text',
    glyph: GLYPHS_NGS.ELIPSES
  }
};

const ICON_SIZE = 100;

const MenuIcon = (url, fill, classs) => <svg xmlns={url} fill={fill} className={classs} />;

class NGSCourseSubHeader extends Component {
  static propTypes = {
    localizedContent: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
    course: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      theme: PropTypes.object
    }),
    isAtLeastTeacher: PropTypes.bool.isRequired
  };

  state = {
    visibleLinks: [],
    moreLinks: [],
    moreLinksVisible: false
  };

  componentDidMount() {
    this.splitLinks();
    window.addEventListener('resize', this.splitLinks);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.splitLinks);
  }

  splitLinks = () => {
    const { isAtLeastTeacher } = this.props;

    const links = isAtLeastTeacher ? TEACHER_LINKS : STUDENT_LINKS;
    let visibleLinks = links;
    let moreLinks = [];

    if (!this.fitInOneRow(links.length)) {
      const numberOfIcons = Math.floor(window.innerWidth / ICON_SIZE) - 1;

      visibleLinks = [...visibleLinks.slice(0, numberOfIcons), 'more'];
      moreLinks = links.slice(numberOfIcons);
    }

    if (visibleLinks.length !== this.state.visibleLinks.length || moreLinks.length !== this.state.moreLinks.length) {
      this.setState({ visibleLinks, moreLinks });
    }
  };

  fitInOneRow = numberOfItems => window.innerWidth / numberOfItems > ICON_SIZE;

  isMoreSelected = (course, moreLinks, pathname) => {
    if (!course || !course.id) {
      return false;
    }
    return (
      moreLinks.filter(linkName => {
        const linkPath = LINKS[linkName].path.replace('{course_id}', course.id.toString());
        return new RegExp(linkPath, 'i').test(pathname);
      }).length > 0
    );
  };

  renderMoreLinks(course, moreLinks) {
    const {
      localizedContent: { siteHeaders: content },
      course: { theme = {} }
    } = this.props;
    const visible = this.state.moreLinksVisible;

    if (visible) {
      return (
        <ul className={styles.hiddenLinks}>
          {moreLinks.map((linkName, index) => {
            const link = LINKS[linkName];
            const linkPath = course && course.id ? link.path.replace('{course_id}', course.id.toString()) : link.path;

            return (
              <li key={index}>
                <Link id={`${linkName}Link`} to={linkPath} onClick={() => this.setState({ moreLinksVisible: false })}>
                  <SVGIcon glyph={link.glyph} />
                  <span>{content[link.title_i18n_key]}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      );
    }
  }

  renderLinks() {
    const {
      localizedContent: { siteHeaders: content },
      pathname,
      course,
      isAtLeastTeacher
    } = this.props;
    const { visibleLinks, moreLinks } = this.state;
    const isMoreSelected = this.isMoreSelected(course, moreLinks, pathname);

    return (
      <ul className={styles.links} role="tablist">
        {this.renderMoreLinks(course, moreLinks)}
        {visibleLinks.map((linkName, index) => {
          const link = LINKS[linkName];
          const linkPath = course && course.id ? link.path.replace('{course_id}', course.id.toString()) : link.path;
          const isMore = linkName === 'more';
          let selected = new RegExp(`${linkPath}$`, 'i').test(pathname);
          if (isMore) {
            selected = isMoreSelected;
          }

          return (
            <li role="tab" aria-controls="maincontent" aria-selected={selected} key={index}>
              <Link
                id={`${linkName}Link`}
                to={linkPath}
                className={classnames(selected ? styles.selected : null, isMore ? styles.more : '')}
                onClick={event => {
                  if (isMore) {
                    this.setState({ moreLinksVisible: !this.state.moreLinksVisible });
                    event.preventDefault();
                  }
                }}
              >
                <SVGIcon glyph={link.glyph} />
                <span role={selected ? 'heading' : null} aria-level={selected ? '3' : null}>
                  {content[link.title_i18n_key]}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const {
      pathname,
      course: { id, title, theme = {} }
    } = this.props;

    const tab = pathname.replace(new RegExp(`.*/${id}/?`, 'i'), '') || 'home';

    let productLogoClass;

    if (theme.productContrastColor) {
      productLogoClass =
        colorLightness(theme.productContrastColor) === 1 ? styles.productLogoWhite : styles.productLogoDark;
    } else if (theme.productColor) {
      productLogoClass = colorLightness(theme.productColor) === 0 ? styles.productLogoWhite : styles.productLogoDark;
    }

    return (
      <header id="subNavMenu">
        <nav
          className={classnames(styles.courseNav, {
            [styles.noLogo]: !theme.productLogoPath,
            [styles.noLogoOnMobile]: tab !== 'home'
          })}
          style={productCSSVariables(theme)}
          aria-label="Secondary"
        >
          <div className="grid">
            <div className="row">
              <div className={classnames('col', 'md6', styles.courseLogo)}>
                <h1 className="a11y-hide">{title}</h1>
                {theme.productLogoPath ? (
                  <img src={theme.productLogoPath} height="45" alt={title} className={productLogoClass} />
                ) : null}
              </div>
              <div className={classnames('col', 'md6', styles.courseLinks)}>
                <h2 className="a11y-hide">Main menu</h2>
                {this.renderLinks()}
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export const LocalizedNGSCourseSubHeader = withLocalizedContent('siteHeaders')(NGSCourseSubHeader);

export default connect(state => ({
  pathname: location.pathname,
  course: state.ngsCourse.course,
  isAtLeastTeacher: roleIsAtLeast(USER_ROLES.TEACHER, state.identity.role)
}))(LocalizedNGSCourseSubHeader);
