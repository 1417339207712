import React from 'react';
import PropTypes from 'prop-types';
import { isNumeric } from '../../utils/number';

const SmartPercentage = ({ percentage }) => {
  if (!isNumeric(percentage)) {
    return <></>;
  }

  return (
    <>
      {percentage}
      <sup>%</sup>
    </>
  );
};

SmartPercentage.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SmartPercentage;
