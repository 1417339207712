import React from 'react';
import PropTypes from 'prop-types';
import { hasValueNotApplicable, hasValueAwaiting } from '../../utils/string';

const SmartTableReportingMark = ({ mark }) => {
  if (hasValueNotApplicable(mark)) {
    return <></>;
  } else if (hasValueAwaiting(mark)) {
    return 'Awaiting';
  }

  return mark;
};

SmartTableReportingMark.propTypes = {
  mark: PropTypes.string
};

export default SmartTableReportingMark;
