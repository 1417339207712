import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartProgressBar.scss';

const SmartProgressBar = ({ percentage }) => {
  const { wrapper, track, bar } = styles;

  return (
    <div className={wrapper}>
      <div className={track}>
        <div className={bar} style={{ width: `${percentage}%` }} />
      </div>
    </div>
  );
};

SmartProgressBar.propTypes = {
  percentage: PropTypes.number
};

export default SmartProgressBar;
