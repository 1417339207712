import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SmartBreadcrumb from '../../SmartBreadcrumb/SmartBreadcrumb';

const SmartBreadcrumbFromTree = ({ tree, activeItemId, setActiveItemId }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const getPathRecursively = (source = [], result = []) => {
    for (let i = 0; i < source.length; i += 1) {
      if (source[i].children !== undefined && getPathRecursively(source[i].children, result)) {
        result.unshift(source[i]);
        return true;
      }
    }

    const child = source.find(c => c.id === activeItemId);
    if (child) {
      result.unshift(child);
      return true;
    }

    return false;
  };

  useEffect(() => {
    const r = [];
    getPathRecursively(tree, r);
    setBreadcrumbs(r);
  }, [activeItemId]);

  return <SmartBreadcrumb breadcrumbs={breadcrumbs} onBreadcrumbClick={breadcrumb => setActiveItemId(breadcrumb.id)} />;
};

SmartBreadcrumbFromTree.propTypes = {
  tree: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setActiveItemId: PropTypes.func.isRequired
};

export default SmartBreadcrumbFromTree;
