import React from 'react';
import PropTypes from 'prop-types';
import SmartOverlayPanelQuestion from './SmartOverlayPanelQuestion';
import styles from './SmartOverlayPanelQuestions.scss';
import classnames from 'classnames';

const SmartOverlayPanelQuestions = ({ questions, ...props }) => {
  const { questionsWrapper } = styles;

  const renderQuestions = items =>
    items.map(question => <SmartOverlayPanelQuestion question={question} key={question.id} />);

  return (
    <div className={classnames(questionsWrapper, props.className)} {...props}>
      {renderQuestions(questions)}
    </div>
  );
};

SmartOverlayPanelQuestions.propTypes = {
  questions: PropTypes.array.isRequired,
  className: PropTypes.string
};

export default SmartOverlayPanelQuestions;
