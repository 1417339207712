import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './SmartContent.scss';
import SmartContentTitleBar from './SmartContentTitle/SmartContentTitleBar';

const SmartContent = ({ contentOverData, contentUnderData, handleRightBtn, handleLeftBtn }) => {
  const { contentWrapper, aditionalContent, contentUnder } = styles;
  const [applyShadow, setApplyShadow] = useState(false);
  const ref = useRef();

  const onScrollHandler = () => {
    const currentRef = ref.current || null;
    setApplyShadow(true);

    if (currentRef !== null) {
      if (currentRef.scrollTop === 0) {
        setApplyShadow(false);
      }
    }
  };

  return (
    <div className={contentWrapper} onScroll={onScrollHandler} ref={ref}>
      <SmartContentTitleBar
        applyShadow={applyShadow}
        titleContent={contentOverData}
        handleRightBtn={handleRightBtn}
        handleLeftBtn={handleLeftBtn}
      />
      {contentUnderData.aditionalData && <div className={aditionalContent}>{contentUnderData.aditionalData}</div>}
      <div className={contentUnder}>
        <div>{contentUnderData.content}</div>
      </div>
    </div>
  );
};

SmartContent.propTypes = {
  contentOverData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
  contentUnderData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
  handleRightBtn: PropTypes.func,
  handleLeftBtn: PropTypes.func
};

export default SmartContent;
