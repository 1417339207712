import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './TableAreaMobile.scss';
import SmartCard from '../../../../components/SmartCard/SmartCard';
import SmartBadge from '../../../../components/SmartBadge/SmartBadge';
import { TaskInfoSidebarContext } from '../ContextProviders';

function TableAreaMobile({ items }) {
  const {
    SmartStudentTasksWrapper,
    SmartStudentTasksRow,
    SmartStudentTasksTitleColumn,
    SmartStudentTasksTitleEmptyColumn,
    SmartStudentTasksDataColumn
  } = styles;

  const taskInfoContext = useContext(TaskInfoSidebarContext);
  const prettifyDate = date => moment.unix(date).format('DD/M/YY');

  const renderTaskEntries = entry => (
    <>
      <div className={SmartStudentTasksRow}>
        <div className={SmartStudentTasksTitleColumn}>
          <a onClick={() => taskInfoContext.showSidebarFor(entry)} aria-hidden="true">
            {entry.task}
          </a>
        </div>
        <div className={SmartStudentTasksTitleEmptyColumn} />
      </div>
      <div className={SmartStudentTasksRow}>
        <div className={SmartStudentTasksDataColumn}>DSE Band</div>
        <div className={SmartStudentTasksDataColumn}>
          {entry.dseValue !== 3 && entry.dseValue !== 4 && <SmartBadge label={entry.dseLabel.toUpperCase()} />}
          {entry.dseValue === 3 && <div>Awaiting</div>}
          {entry.dseValue === 4 && <div>n/a</div>}
        </div>
      </div>
      <div className={SmartStudentTasksRow}>
        <div className={SmartStudentTasksDataColumn}>Mark</div>
        <div className={SmartStudentTasksDataColumn}>
          {entry.markLabel !== null && entry.markLabel !== 'awaiting' ? entry.markLabel : <div>Awaiting</div>}
        </div>
      </div>
      <div className={SmartStudentTasksRow}>
        <div className={SmartStudentTasksDataColumn}>%</div>
        <div className={SmartStudentTasksDataColumn}>
          {entry.percentageLabel !== null && entry.percentageLabel !== 'awaiting' ? (
            <>
              {entry.percentageLabel}
              {entry.percentageLabel !== 'n/a' && entry.percentageLabel !== 'awaiting' && <sup>%</sup>}
            </>
          ) : (
            <div>Awaiting</div>
          )}
        </div>
      </div>
      {/* <div className={SmartStudentTasksRow}>
        <div className={SmartStudentTasksDataColumn}>Effort Points</div>
        <div className={SmartStudentTasksDataColumn}>
          {entry.effortPoints !== null ? entry.effortPoints : <div>Awaiting</div>}
        </div>
      </div>
      <div className={SmartStudentTasksRow}>
        <div className={SmartStudentTasksDataColumn}>Accuracy Points</div>
        <div className={SmartStudentTasksDataColumn}>
          {entry.accuracyPoints !== null ? entry.accuracyPoints : <div>Awaiting</div>}
        </div>
      </div> */}
      <div className={SmartStudentTasksRow}>
        <div className={SmartStudentTasksDataColumn}>Date Completed</div>
        <div className={SmartStudentTasksDataColumn}>
          {prettifyDate(entry.dateCompleted) !== null ? prettifyDate(entry.dateCompleted) : <div>Awaiting</div>}
        </div>
      </div>
      <div className={SmartStudentTasksRow}>
        <div className={SmartStudentTasksDataColumn}>Content Type</div>
        <div className={SmartStudentTasksDataColumn}>
          {entry.contentType !== null ? entry.contentType : <div>Awaiting</div>}
        </div>
      </div>
    </>
  );

  return (
    <>
      {items.map((item, index) => {
        let roundedBorder;

        if (index === 0 && items.length === 1) {
          roundedBorder = 'all';
        } else if (index === 0) {
          roundedBorder = 'top';
        } else if (index === items.length - 1) {
          roundedBorder = 'bottom';
        } else {
          roundedBorder = 'none';
        }

        return (
          <SmartCard noPadding hasBorder roundedBorder={roundedBorder} key={index}>
            <div className={SmartStudentTasksWrapper}>{renderTaskEntries(item)}</div>
          </SmartCard>
        );
      })}
    </>
  );
}

TableAreaMobile.propTypes = {
  items: PropTypes.array.isRequired
};

export default TableAreaMobile;
