import PropTypes from 'prop-types';
import React from 'react';
import styles from './StackedBarChart.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

function StackedBarChart({ items, withIcons }) {
  const calculateHeightForCurrentElement = elementIndex => (items.length - elementIndex) * 40 + 20;

  const renderStudents = (students, link, linked) => {
    if (linked) {
      return (
        <>
          <SVGIcon glyph={GLYPHS.ICON_STUDENT_LINKED} className={styles.stackedBarChartIcon} />
          <p>
            <a href={link}>{students}</a>
          </p>
        </>
      );
    }
    return (
      <>
        <SVGIcon glyph={GLYPHS.ICON_STUDENT} className={styles.stackedBarChartIcon} />
        <p>{students}</p>
      </>
    );
  };

  return (
    <div className={styles.stackedBarChart}>
      <div className={styles.stackedBarChartContainer}>
        {items.map((item, index) => {
          const { percentage, color, label, students, link, linked } = item;
          return (
            <div
              key={index}
              className={styles.stackedBarChartItem}
              style={{ height: `${calculateHeightForCurrentElement(index)}px`, width: `${percentage}%` }}
            >
              <div className={styles.stackedBarChartBar} style={{ background: color }} />
              <div className={styles.stackedBarChartLabel}>
                <div>
                  {percentage}
                  <sup>%</sup>
                  {label}
                </div>
                {withIcons && (
                  <div className={styles.stackedBarChartInfo}>{renderStudents(students, link, linked)}</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

StackedBarChart.propTypes = {
  items: PropTypes.array.isRequired,
  withIcons: PropTypes.bool
};

export default StackedBarChart;
