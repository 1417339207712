import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './TaskInfoArea.scss';
import { hasValueNotApplicable } from '../../../../utils/string';
import SmartOverlayPanel from '../../../../components/SmartOverlayPanel/SmartOverlayPanel';
import SmartContent from '../../../../components/SmartContent/SmartContent';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import SmartCard from '../../../../components/SmartCard/SmartCard';
import { canRenderDseBand, renderDseBand, renderMarkRow, renderSupportLinks } from './utils';
import SmartListItem from '../../../../components/SmartList/SmartListItem/SmartListItem';
import SmartOverlayPanelQuestions from './SmartOverlayPanelQuestions/SmartOverlayPanelQuestions';
import SmartOverlayPanelTableLink from './SmartOverlayPanelTableLink/SmartOverlayPanelTableLink';

function TaskInfoAreaAssessments({ isOpen, handleClose, animation, assessment }) {
  const { wrapper, sectionTitle, wrapperLast } = styles;
  const [areAnswersOpen, setAreAnswersOpen] = useState(false);

  useEffect(() => () => setAreAnswersOpen(false), [isOpen]);

  if (!assessment) {
    return <></>;
  }

  const { markLabel, percentageLabel, dseLabel, dateCompleted, sidePanelInfo } = assessment;

  return (
    <SmartOverlayPanel isOpen={isOpen} handleClose={handleClose} animation={animation}>
      <SmartContent
        contentOverData={{
          title: `[${assessment.task}]`,
          iconRight: GLYPHS.ICON_CLOSE
        }}
        contentUnderData={{
          content: (
            <>
              <SmartCard noPadding hasBorder>
                <div className={wrapper}>
                  <div className={sectionTitle}>Results</div>
                  {canRenderDseBand(dseLabel) && <SmartListItem label="DSE band:" value={renderDseBand(dseLabel)} />}
                  <SmartListItem label="Date completed:" value={moment.unix(dateCompleted).format('DD/M/YY')} />
                  {!hasValueNotApplicable(markLabel) && (
                    <SmartListItem
                      label="Mark:"
                      value={renderMarkRow(markLabel, percentageLabel)}
                      extraValue={
                        sidePanelInfo.questions.length > 0 && (
                          <a onClick={() => setAreAnswersOpen(!areAnswersOpen)} aria-hidden="true">
                            {areAnswersOpen ? 'Hide your answers' : 'Review your answers'}
                          </a>
                        )
                      }
                    />
                  )}
                  {areAnswersOpen && <SmartOverlayPanelQuestions questions={sidePanelInfo.questions} />}
                </div>
              </SmartCard>
              <SmartCard noPadding>
                <div className={wrapperLast}>
                  <div className={sectionTitle}>About</div>
                  <SmartListItem label="Subject:" value={assessment.subject} />
                  <SmartListItem label="Theme:" value={assessment.sidePanelInfo.theme} />
                  <SmartListItem label="Content type:" value={assessment.contentType} />
                  <SmartListItem label="Assigned by:" value={assessment.assignedBy} />
                  <SmartListItem
                    label="Assignment:"
                    value={
                      <SmartOverlayPanelTableLink
                        link={assessment.sidePanelInfo.contentAssignment.url}
                        displayText={assessment.sidePanelInfo.contentAssignment.name}
                      />
                    }
                  />
                  {renderSupportLinks(sidePanelInfo.contentSupport)}
                </div>
              </SmartCard>
            </>
          )
        }}
        handleRightBtn={handleClose}
      />
    </SmartOverlayPanel>
  );
}

TaskInfoAreaAssessments.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  animation: PropTypes.oneOf(['left', 'right']),
  assessment: PropTypes.object
};

export default TaskInfoAreaAssessments;
