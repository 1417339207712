import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styles from './NavTabItem.scss';
import SVGIcon from '../SVGIcon/SVGIcon';

function NavTabItem({ onSelectedTab, onClickDetails, item, activeItemKey, expandedItemKey }) {
  const { id: tabId, type, name, details } = item;
  const activeClass = activeItemKey === tabId ? styles.active : '';
  const isExpanded = expandedItemKey === tabId;

  const selectTabHandler = (e, tabItemId) => {
    if (activeItemKey === tabItemId) {
      return;
    }
    onSelectedTab(tabItemId);
  };

  const clickDetailsHandler = (e, tabItemId, expanded) => {
    onClickDetails(tabItemId, expanded);
  };

  return (
    <li className={`${styles.tabItem} ${activeClass}`} key={tabId}>
      <div
        className={styles.mainWrapper}
        onClick={e => selectTabHandler(e, tabId)}
        role="button"
        tabIndex={tabId}
        aria-hidden
      >
        <div className={styles.leftWrapper}>
          <span className={styles.tabItemType}>{type}</span>
          <span className={styles.tabItemTitle}>{name}</span>
        </div>
        {details && details.label && (
          <div className={classnames([isExpanded && styles.expanded, styles.rightWrapper])}>
            <div onClick={e => clickDetailsHandler(e, tabId, isExpanded)} role="button" tabIndex={tabId} aria-hidden>
              <span className={styles.tabItemMoreLabel}>{details.label}</span>
              <span className={styles.tabItemMoreIcon}>
                {details.icon && <SVGIcon className={styles.dropdownArrow} glyph={details.icon} />}
              </span>
            </div>
          </div>
        )}
      </div>
    </li>
  );
}

NavTabItem.propTypes = {
  onSelectedTab: PropTypes.func.isRequired,
  onClickDetails: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    details: PropTypes.object
  }).isRequired,
  activeItemKey: PropTypes.string,
  expandedItemKey: PropTypes.string
};

export default NavTabItem;
