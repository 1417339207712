import React from 'react';
import PropTypes from 'prop-types';
import SecondLevelTabsItem from './SecondLevelTabsItem';
import styles from './SecondLevelTabsWrapper.scss';

/**
 * Component that renderes a list of tabs and actions.
 * If scroll is needed add the HorizontalScrollContainer in a new component that wraps
 * the current content of this one. It should be conditionally rendered.
 */
function SecondLevelTabsWrapper({ items, activeItemKey, handleClickItem, hasFilters, actions, infoButton }) {
  const {
    secondLevelTabsWrapper,
    secondLevelTabsWrapperTabs,
    secondLevelTabsWrapperActions,
    secondLevelTabsWrapperActionsItem,
    secondLevelTabsWrapperFilterIcon,
    secondLevelTabsWrapperInfoIcon
  } = styles;

  const renderTabs = () =>
    items.map((item, index) => (
      <SecondLevelTabsItem
        key={index}
        item={item}
        activeItemKey={!activeItemKey && index === 0 ? items[index].key : activeItemKey}
        handleClickItem={handleClickItem}
      />
    ));

  const renderActions = () => {
    if (actions && actions.length > 0) {
      return actions.map((action, index) => (
        <div
          key={index}
          className={secondLevelTabsWrapperActionsItem}
          onClick={action.handleClick}
          role="button"
          tabIndex={index}
          aria-hidden
        >
          {action.element}
          {action.icon && <div className={secondLevelTabsWrapperFilterIcon}>{action.icon}</div>}
        </div>
      ));
    }

    return null;
  };

  if (!items || items.length === 0) {
    return <div>No items...</div>;
  }

  return (
    <div className={secondLevelTabsWrapper}>
      <div className={secondLevelTabsWrapperTabs}>{renderTabs()}</div>
      {hasFilters && <div className={secondLevelTabsWrapperActions}>{renderActions()}</div>}
      {infoButton && <div className={secondLevelTabsWrapperInfoIcon}>{infoButton}</div>}
    </div>
  );
}

SecondLevelTabsWrapper.propTypes = {
  // items - List of tabs that will be rendered.
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired,
  //   activeItemKey - the items that is active in the list (not required, by default the first one is active)
  activeItemKey: PropTypes.string,
  //   handleClickItem - method that handles item click
  handleClickItem: PropTypes.func.isRequired,
  //   hasFilters - boolean to show/hide filters
  hasFilters: PropTypes.bool,
  // actions - displayed on the right hand side of the wrapper (contains: date, filter)
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      element: PropTypes.node.isRequired,
      icon: PropTypes.any,
      handleClick: PropTypes.func.isRequired
    })
  ),
  infoButton: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.string])
};

export default SecondLevelTabsWrapper;
