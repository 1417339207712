import React, { Component } from 'react';

import env from '../../../../globals/envSettings';

import Button, { buttonTypes } from '../../../../components/Button/Button.js';
import styles from './SmartDemoLoginPage.scss';

class SmartDemoLoginPage extends Component {
  constructor() {
    super();
    this.state = {
      region: localStorage.getItem('region'),
      language: localStorage.getItem('language')
    };
  }

  onLocationSelect = e => {
    const selectedRegion = e.target.value;

    if (this.state.region !== selectedRegion) {
      localStorage.setItem('region', selectedRegion); // Remove this localStorage item on user Signout
      this.setState({ region: selectedRegion });
    }
  };

  onLanguageSelect = e => {
    const selectedLanguage = e.target.value;

    if (this.state.language !== selectedLanguage) {
      localStorage.setItem('language', selectedLanguage); // Remove this localStorage item on user Signout
      this.setState({ language: selectedLanguage });
    }
  };

  render() {
    let services = [];
    let languages = [];
    if (env.smart) {
      services = env.smart.services;
      languages = env.smart.languages;
    }

    const selectedRegion = this.state.region;
    const selectedLanguage = this.state.language;

    return (
      <div className={`${styles.demoPage} grid`}>
        <div className="row">
          <div className="col">
            <h1>Smart Demo Login Simulator</h1>
            <label htmlFor="location-dropdown">
              <b style={{ marginRight: '1em' }}>User Location</b>
              <select id="location-dropdown" value={selectedRegion} onChange={this.onLocationSelect}>
                <option value="">Please select location</option>
                {services.map((service, index) => (
                  <option value={service.key} key={index}>
                    {service.name}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="language-dropdown">
              <b style={{ marginRight: '1em' }}>User Language</b>
              <select id="language-dropdown" value={selectedLanguage} onChange={this.onLanguageSelect}>
                <option value="">Please select language</option>
                {languages.map((language, index) => (
                  <option value={language.key} key={index}>
                    {language.name}
                  </option>
                ))}
              </select>
            </label>
            <br /> <br />
            <Button
              id="register-button"
              to={'/smart'}
              type={buttonTypes.PRIMARY}
              text={'View Smart Home Page'}
              disabled={!selectedRegion}
              dataTestId="GOTO_REGISTER"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SmartDemoLoginPage;
