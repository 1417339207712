import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { students } from '../../structure/SmartTeacherLayout/mocks.json';

const SmartTeacherReportingGroupTasks = ({ params }) => (
  <div>
    <h1>Tasks for group ( {params.groupId})</h1>
    <h2>Student list</h2>
    {students
      .filter(student => student.group_id === params.groupId)
      .map(student => (
        <div key={student.id}>
          <Link to={`/dev/teacher-reporting/student/${student.id}/tasks`}>{student.name} - tasks</Link>
        </div>
      ))}
  </div>
);

SmartTeacherReportingGroupTasks.propTypes = {
  params: PropTypes.shape({ groupId: PropTypes.string.isRequired })
};

export default SmartTeacherReportingGroupTasks;
