import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartBreadcrumb.scss';

import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

const SmartBreadcrumb = ({ breadcrumbs, onBreadcrumbClick }) => {
  const { wrapper, element, elementLink, elementIcon, iconArrow } = styles;

  const handleItemClick = selectedElement => {
    onBreadcrumbClick(selectedElement);
  };

  const renderBreadcrumb = () => (
    <div className={wrapper}>
      {breadcrumbs.map((item, index) => (
        <React.Fragment key={index}>
          <div
            key={index}
            aria-hidden="true"
            className={element}
            onClick={() => (breadcrumbs.indexOf(item) !== breadcrumbs.length - 1 ? handleItemClick(item) : '')}
          >
            {breadcrumbs.indexOf(item) !== breadcrumbs.length - 1 ? (
              <a className={elementLink}>{item.name}</a>
            ) : (
              <p>{item.name}</p>
            )}
          </div>
          {breadcrumbs.indexOf(item) !== breadcrumbs.length - 1 && (
            <div className={elementIcon}>
              <SVGIcon className={iconArrow} glyph={GLYPHS.ICON_SMALL_BREADCRUMB_CHEVRON} fill="#5c5c5c" />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );

  return <div>{renderBreadcrumb()}</div>;
};

SmartBreadcrumb.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
  onBreadcrumbClick: PropTypes.func
};

export default SmartBreadcrumb;
