import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatisticalLozenges.scss';

function StatisticalLozenges({ icon, text, component }) {
  const { statisticalLozengesPill } = styles;
  return (
    <div style={{ display: 'flex' }}>
      <div className={statisticalLozengesPill}>
        {icon !== undefined && icon}
        {text !== undefined && <p>{text}</p>}
        {component !== undefined && component}
      </div>
    </div>
  );
}

StatisticalLozenges.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  component: PropTypes.element
};

export default StatisticalLozenges;
