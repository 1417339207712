import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import getItemContentType from '../../utils/itemcontentTypes';

const TaskInfoSidebarContext = createContext({
  type: '',
  data: {},
  isVisible: false,
  hideSidebar: undefined,
  showSidebarFor: undefined
});

function TaskInfoSidebarContextProvider({ children }) {
  const [type, setType] = useState('');
  const [data, setData] = useState({});
  const [isVisible, setIsVisible] = useState(false);

  const hideSidebar = () => {
    console.log('hiding sidebar');
    setIsVisible(false);
    setData({});
    setType('');
  };

  const showSidebarFor = record => {
    setData(record);
    setType(getItemContentType(record));
    setIsVisible(true);
  };

  return (
    <TaskInfoSidebarContext.Provider
      value={{
        type,
        data,
        isVisible,
        hideSidebar,
        showSidebarFor
      }}
    >
      {children}
    </TaskInfoSidebarContext.Provider>
  );
}

TaskInfoSidebarContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export { TaskInfoSidebarContext, TaskInfoSidebarContextProvider };
