import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const SortingContext = createContext({
  column: '',
  direction: '',
  options: [],
  setSortingColumn: undefined,
  setSortingDirection: undefined
});

function SortingContextProvider({ children }) {
  const [column, setColumn] = useState('date_completed');
  const [direction, setDirection] = useState('desc');

  const setSortingColumn = col => setColumn(col);
  const setSortingDirection = dir => (dir ? setDirection(dir) : setDirection('asc'));

  return (
    <SortingContext.Provider
      value={{
        column,
        direction,
        options: [
          { id: 'task', label: 'Task' },
          { id: 'dse_band', label: 'DSE Band' },
          { id: 'mark', label: 'Mark/%' },
          { id: 'date_completed', label: 'Date completed' },
          { id: 'content_type', label: 'Content type' }
        ],
        setSortingColumn,
        setSortingDirection
      }}
    >
      {children}
    </SortingContext.Provider>
  );
}

SortingContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export { SortingContext, SortingContextProvider };
