import React, { createContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SmartStudentReportingTasks.scss';
import SmartStudentsReportTaskContainer from './SmartStudentsReportTasksTable/SmartStudentsReportTasksTableContainer';
import SmartDropdown from '../SmartDropdown/SmartDropdown';
import SmartStudentReportingTasksStatisticsArea from './SmartStudentReportingTasksStatisticsArea';
import useMediaQuery from '../../utils/mediaQuery';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import SmartPinkDot from '../SmartPinkDot/SmartPinkDot';
import SmartLoadingSpinner from '../SmartLoadingSpinner/SmartLoadingSpinner';

export const sortAndFilterOverlayContext = createContext(null);

const SmartStudentReportingTasks = () => {
  const { filters, filterMobile, filterMobileContainer, filterMobileDot, filterIcon, table } = styles;

  const [items, setItems] = useState([]);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [displayPinkDot, setDisplayPinkDot] = useState({
    filterApplied: false,
    contentType: false,
    subject: false,
    assignedBy: false
  });

  const handleItemClick = () => {
    setIsOverlayOpen(!isOverlayOpen);
  };

  const handleOverlayPanelClose = () => {
    setIsOverlayOpen(!isOverlayOpen);
  };

  const fetchData = async () => {
    const response = await (await fetch(`${__API_BASE__}/open/smart/student-reporting/1/tasks`)).json();
    setItems(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const extractFilterOptionsFromDataset = field => {
    const result = {};
    items.forEach(item => {
      result[item[field]] = 1;
    });
    return [
      { id: `All_${field}`, label: 'All' },
      ...Object.keys(result).map(item => ({ id: `${item}_${field}`, label: item }))
    ];
  };

  const taskTypesFilterValues = extractFilterOptionsFromDataset('contentType');
  const subjectsFilterValues = extractFilterOptionsFromDataset('subject');
  const assignedByFilterValues = extractFilterOptionsFromDataset('assignedBy');
  const sortByFilterValues = [
    { id: 'task', label: 'Task' },
    { id: 'dse_band', label: 'DSE Band' },
    { id: 'mark', label: 'Mark/%' },
    { id: 'date_completed', label: 'Date completed' },
    { id: 'content_type', label: 'Content type' }
  ];

  const [contentTypeFilterValue, setcontentTypeFilterValue] = useState(
    extractFilterOptionsFromDataset('contentType')[0]
  );
  const [subjectFilterValue, setSubjectFilterValue] = useState(extractFilterOptionsFromDataset('subject')[0]);
  const [assignedByFilterValue, setAssignedByFilterValue] = useState(extractFilterOptionsFromDataset('assignedBy')[0]);
  const [sortByFilterValue, setSortByFilterValue] = useState({ id: 'date_completed', label: 'Date completed' });

  const isMobile = useMediaQuery('(max-width: 1023px)');
  const pageRef = useRef();

  const onClickBackToTopHandler = () => {
    const currentRef = pageRef.current || null;

    if (currentRef !== null) {
      currentRef.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleChange = value => {
    if (sortByFilterValues.some(sortByValue => sortByValue.id === (value.id ? value.id : value))) {
      setSortByFilterValue(value);
    }

    if (taskTypesFilterValues.some(contentTypeValue => contentTypeValue.id === (value.id ? value.id : value))) {
      setcontentTypeFilterValue(value);

      if ((value.label ? value.label : value) !== 'All') {
        setDisplayPinkDot(prevState => ({ ...prevState, filterApplied: true, contentType: true }));
      } else {
        setDisplayPinkDot(prevState => ({ ...prevState, filterApplied: false, contentType: false }));
      }
    }

    if (subjectsFilterValues.some(subjectValue => subjectValue.id === (value.id ? value.id : value))) {
      setSubjectFilterValue(value);

      if ((value.label ? value.label : value) !== 'All') {
        setDisplayPinkDot(prevState => ({ ...prevState, filterApplied: true, subject: true }));
      } else {
        setDisplayPinkDot(prevState => ({ ...prevState, filterApplied: false, subject: false }));
      }
    }

    if (assignedByFilterValues.some(assignedByValue => assignedByValue.id === (value.id ? value.id : value))) {
      setAssignedByFilterValue(value);

      if ((value.label ? value.label : value) !== 'All') {
        setDisplayPinkDot(prevState => ({ ...prevState, filterApplied: true, assignedBy: true }));
      } else {
        setDisplayPinkDot(prevState => ({ ...prevState, filterApplied: false, assignedBy: false }));
      }
    }
  };

  const handleClearFilters = () => {
    setcontentTypeFilterValue(extractFilterOptionsFromDataset('contentType')[0]);
    setSubjectFilterValue(extractFilterOptionsFromDataset('subject')[0]);
    setAssignedByFilterValue(extractFilterOptionsFromDataset('assignedBy')[0]);
    setDisplayPinkDot({
      filterApplied: false,
      contentType: false,
      subject: false,
      assignedBy: false
    });
  };

  const renderTable = () => {
    if (!items || items.length === 0) {
      return <SmartLoadingSpinner />;
    }

    return (
      <SmartStudentsReportTaskContainer
        items={items}
        filters={{
          contentType: contentTypeFilterValue,
          subject: subjectFilterValue,
          assignedBy: assignedByFilterValue
        }}
        handleBackToTopClick={onClickBackToTopHandler}
      />
    );
  };

  return (
    <div ref={pageRef}>
      <SmartStudentReportingTasksStatisticsArea items={items} />
      {isMobile && (
        <>
          <div className={filterMobile}>
            <div className={filterMobileContainer} aria-hidden="true" onClick={handleItemClick}>
              {(displayPinkDot.filterApplied ||
                displayPinkDot.contentType ||
                displayPinkDot.subject ||
                displayPinkDot.assignedBy) && (
                <div className={filterMobileDot}>
                  <SmartPinkDot />
                </div>
              )}
              <div>
                <SVGIcon glyph={GLYPHS.ICON_FILTER_ROUNDED} className={filterIcon} />
              </div>
            </div>
          </div>
        </>
      )}

      {!isMobile && (
        <>
          <div className={filters}>
            <SmartDropdown
              filterText="Task type"
              options={taskTypesFilterValues}
              value={contentTypeFilterValue}
              handleSetValueAction={value => setcontentTypeFilterValue(value)}
            />
            <SmartDropdown
              filterText="Subject"
              options={subjectsFilterValues}
              value={subjectFilterValue}
              handleSetValueAction={value => setSubjectFilterValue(value)}
            />
            <SmartDropdown
              filterText="Assigned by"
              options={assignedByFilterValues}
              value={assignedByFilterValue}
              handleSetValueAction={value => setAssignedByFilterValue(value)}
            />
          </div>
        </>
      )}

      <div className={table}>
        <sortAndFilterOverlayContext.Provider
          value={{
            isOverlayOpen,
            filters: {
              sortBy: sortByFilterValues,
              contentType: taskTypesFilterValues,
              assignedBy: assignedByFilterValues,
              subject: subjectsFilterValues
            },
            selectedFilters: {
              selectedSortBy: sortByFilterValue
            },
            displayPinkDot,
            handleChange,
            handleClearFilters,
            handleOverlayPanelClose
          }}
        >
          {renderTable()}
        </sortAndFilterOverlayContext.Provider>
      </div>
    </div>
  );
};

SmartStudentReportingTasks.propTypes = {
  params: PropTypes.shape({ studentId: PropTypes.string.isRequired })
};

export default SmartStudentReportingTasks;
