import React, { useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { SortingContext, TaskInfoSidebarContext } from '../ContextProviders';
import SmartTable from '../../../../components/SmartTable/SmartTable';
import SmartTableHeader from '../../../../components/SmartTable/SmartTableHeader/SmartTableHeader';
import SmartTableHeaderRow from '../../../../components/SmartTable/SmartTableHeaderRow/SmartTableHeaderRow';
import SmartTableBody from '../../../../components/SmartTable/SmartTableBody/SmartTableBody';
import SmartTableRow from '../../../../components/SmartTable/SmartTableRow/SmartTableRow';
import SmartTableCell from '../../../../components/SmartTable/SmartTableCell/SmartTableCell';
import SmartTableHeaderSortableCell from '../../../../components/SmartTable/SmartTableHeaderSortableCell/SmartTableHeaderSortableCell';
import SmartTableCellBand from '../../../../components/SmartTable/SmartTableCellBand/SmartTableCellBand';

function TableAreaDesktop({ items }) {
  const sortContext = useContext(SortingContext);
  const taskInfoContext = useContext(TaskInfoSidebarContext);

  const handleSortAction = column => {
    let direction = 'asc';
    if (column === sortContext.column) direction = sortContext.direction === 'asc' ? 'desc' : 'asc';
    sortContext.setSortingColumn(column);
    sortContext.setSortingDirection(direction);
  };

  const renderColumns = () => (
    <>
      <SmartTableHeaderSortableCell
        order="first"
        borderType="normal"
        colKey="task"
        handleSortClick={handleSortAction}
        sortDir={sortContext.direction}
        sortElement={sortContext.column}
      >
        Task
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="dse_band"
        handleSortClick={handleSortAction}
        sortDir={sortContext.direction}
        sortElement={sortContext.column}
      >
        DSE Band
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="mark"
        handleSortClick={handleSortAction}
        sortDir={sortContext.direction}
        sortElement={sortContext.column}
      >
        Mark
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="between"
        borderType="dashed"
        colKey="mark_percentage"
        handleSortClick={handleSortAction}
        sortDir={sortContext.direction}
        sortElement={sortContext.column}
      >
        %
      </SmartTableHeaderSortableCell>
      {/* <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="effort_points"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        Effort points
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="accuracy_points"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        Accuracy points
      </SmartTableHeaderSortableCell> */}
      <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="date_completed"
        handleSortClick={handleSortAction}
        sortDir={sortContext.direction}
        sortElement={sortContext.column}
      >
        <>Date completed</>
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="last"
        borderType="normal"
        colKey="content_type"
        handleSortClick={handleSortAction}
        sortDir={sortContext.direction}
        sortElement={sortContext.column}
      >
        Content type
      </SmartTableHeaderSortableCell>
    </>
  );

  const prettifyDate = date => moment.unix(date).format('DD/M/YY');

  const renderMarkPercentageCell = (data, type) => {
    if (data) {
      if (data === 'awaiting') {
        return <span style={{ fontStyle: 'italic' }}>Awaiting</span>;
      }
      if (data === 'n/a') {
        return <span>n/a</span>;
      }

      if (type === 'mark') {
        return <>{data}</>;
      }

      return (
        <>
          {data}
          <sup>%</sup>
        </>
      );
    }
    return <span style={{ fontStyle: 'italic' }}>Awaiting</span>;
  };

  const renderRows = () =>
    items.map((item, index) => (
      <SmartTableRow key={index} type={index % 2 !== 0 ? 'even' : 'odd'}>
        <SmartTableCell order="first" width="400px">
          <a onClick={() => taskInfoContext.showSidebarFor(item)} aria-hidden="true">
            {item.task}
          </a>
        </SmartTableCell>
        <SmartTableCellBand dataType={item.dseLabel}>{item.dseLabel}</SmartTableCellBand>
        <SmartTableCell>{renderMarkPercentageCell(item.markLabel, 'mark')}</SmartTableCell>
        <SmartTableCell borderType="dashed">
          {renderMarkPercentageCell(item.percentageLabel, 'percentage')}
        </SmartTableCell>
        {/* <SmartTableCell>{item.effortPoints}</SmartTableCell>
        <SmartTableCell>{item.accuracyPoints}</SmartTableCell> */}
        <SmartTableCell>{prettifyDate(item.dateCompleted)}</SmartTableCell>
        <SmartTableCell order="last">{item.contentType}</SmartTableCell>
      </SmartTableRow>
    ));

  return (
    <SmartTable>
      <SmartTableHeader>
        <SmartTableHeaderRow>{renderColumns()}</SmartTableHeaderRow>
      </SmartTableHeader>
      <SmartTableBody>{renderRows()}</SmartTableBody>
    </SmartTable>
  );
}

TableAreaDesktop.propTypes = {
  items: PropTypes.array.isRequired
};

export default TableAreaDesktop;
