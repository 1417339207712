import React from 'react';
import StatisticalLozenges from '../StatisticalLozenges/StatisticalLozenges';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './SmartStudentReportingTasksStatisticsArea.scss';
import useMediaQuery from '../../utils/mediaQuery';
import PropTypes from 'prop-types';
import SmartList from '../SmartList/SmartList';
import SmartListItem from '../SmartList/SmartListItem/SmartListItem';

const SmartStudentReportingTasksStatisticsArea = ({ items }) => {
  const { lozenges, lozengesWrapper, lozengesItem, iconNormal } = styles;

  // values are not final
  const assignmentsCompleted = items.length;
  const levelUpsCompleted = items.filter(item => item.contentType === 'Next Steps').length;
  const reactivatesCompleted = items.filter(item => item.contentType === 'Assignment').length;

  const fields = [
    {
      icon: <SVGIcon glyph={GLYPHS.ICON_ASSESSMENTS} className={iconNormal} />,
      number: assignmentsCompleted,
      title: 'Assignments Complete'
    },
    {
      icon: <SVGIcon glyph={GLYPHS.ICON_LEVEL_UP} className={iconNormal} />,
      number: levelUpsCompleted,
      title: 'Level Ups Completed'
    },
    {
      icon: <SVGIcon glyph={GLYPHS.ICON_MEMORY_STRENGTH} className={iconNormal} />,
      number: reactivatesCompleted,
      title: 'Reactivates Completed'
    }
  ];

  const renderForDesktop = () => (
    <div className={lozenges}>
      <div className={lozengesWrapper}>
        <div className={lozengesItem}>
          <StatisticalLozenges
            icon={<SVGIcon className={iconNormal} glyph={GLYPHS.ICON_ASSESSMENTS} />}
            text={assignmentsCompleted}
          />
          <span>Assignments Completed</span>
        </div>
        <div className={lozengesItem}>
          <StatisticalLozenges
            text={levelUpsCompleted}
            icon={<SVGIcon className={iconNormal} glyph={GLYPHS.ICON_LEVEL_UP} />}
          />
          <span>Level Ups Completed</span>
        </div>
        <div className={lozengesItem}>
          <StatisticalLozenges
            text={reactivatesCompleted}
            icon={<SVGIcon className={iconNormal} glyph={GLYPHS.ICON_MEMORY_STRENGTH} />}
          />
          <span>Reactivates Completed</span>
        </div>
      </div>
    </div>
  );

  const renderForMobile = () => (
    <SmartList>
      <SmartListItem
        className="smartList"
        label={fields[0].icon}
        value={fields[0].number}
        extraValue={fields[0].title}
      />
      <SmartListItem
        className="smartList"
        label={fields[1].icon}
        value={fields[1].number}
        extraValue={fields[1].title}
      />
      <SmartListItem
        className="smartList"
        label={fields[2].icon}
        value={fields[2].number}
        extraValue={fields[2].title}
      />
    </SmartList>
  );

  const isMobile = useMediaQuery('(max-width: 1023px)');
  return isMobile ? renderForMobile() : renderForDesktop();
};

SmartStudentReportingTasksStatisticsArea.propTypes = {
  items: PropTypes.array.isRequired
};

export default SmartStudentReportingTasksStatisticsArea;
