import React from 'react';
import PropTypes from 'prop-types';
import SmartTable from '../../../SmartTable/SmartTable';
import SmartTableHeader from '../../../SmartTable/SmartTableHeader/SmartTableHeader';
import SmartTableHeaderRow from '../../../SmartTable/SmartTableHeaderRow/SmartTableHeaderRow';
import SmartTableBody from '../../../SmartTable/SmartTableBody/SmartTableBody';
import SmartTableRow from '../../../SmartTable/SmartTableRow/SmartTableRow';
import SmartTableCell from '../../../SmartTable/SmartTableCell/SmartTableCell';
import SmartTableHeaderSortableCell from '../../../SmartTable/SmartTableHeaderSortableCell/SmartTableHeaderSortableCell';
import styles from './SmartStudentReportCoursesTable.scss';

const SmartStudentReportCoursesTable = ({
  items,
  // columns,
  handleSortAction,
  sortDir,
  sortElement,
  handleItemClick,
  firstColumn
}) => {
  if (items.length === 0) {
    return <div>No data items to be displayed...</div>;
  }

  const renderBaseColumns = () => (
    <>
      <SmartTableHeaderSortableCell
        order="first"
        colKey={firstColumn.key}
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        <>{firstColumn.label}</>
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        colKey="developing"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        <>Developing</>
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        colKey="secure"
        borderType="dashed"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        <>Secure</>
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        colKey="extending"
        borderType="dashed"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        <>Extending</>
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        colKey="questionsAnswered"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        <>Questions Answered</>
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="last"
        colKey="memoryStrength"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        <>Memory Strength</>
      </SmartTableHeaderSortableCell>
    </>
  );

  const renderRows = () =>
    items.map((item, index) => (
      <SmartTableRow key={item.id} type={index % 2 !== 0 ? 'even' : 'odd'}>
        <SmartTableCell order="first" width="400px">
          {item.children !== undefined ? (
            <a onClick={() => handleItemClick(item.id, item.name)} aria-hidden="true" className={styles.itemLink}>
              {item.name}
            </a>
          ) : (
            <>{item.name}</>
          )}
        </SmartTableCell>
        <SmartTableCell>
          {item.developing}
          <sup className={styles.percentSymbol}>%</sup>
        </SmartTableCell>
        <SmartTableCell borderType="dashed">
          {item.secure}
          <sup className={styles.percentSymbol}>%</sup>
        </SmartTableCell>
        <SmartTableCell borderType="dashed">
          {item.extending}
          <sup className={styles.percentSymbol}>%</sup>
        </SmartTableCell>
        <SmartTableCell>{item.questionsAnswered}</SmartTableCell>
        <SmartTableCell order="last">
          {item.memoryStrength}
          <sup className={styles.percentSymbol}>%</sup>
        </SmartTableCell>
      </SmartTableRow>
    ));

  return (
    <SmartTable>
      <SmartTableHeader>
        <SmartTableHeaderRow>{renderBaseColumns()}</SmartTableHeaderRow>
      </SmartTableHeader>
      <SmartTableBody>{renderRows()}</SmartTableBody>
    </SmartTable>
  );
};

SmartStudentReportCoursesTable.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        contentType: PropTypes.string,
        developing: PropTypes.number,
        secure: PropTypes.number,
        extending: PropTypes.number,
        memoryStrength: PropTypes.number,
        questionsAnswered: PropTypes.number,
        // children has the same shape as an item
        children: PropTypes.array
      })
    ),
    PropTypes.array
  ]).isRequired,
  handleSortAction: PropTypes.func.isRequired,
  sortElement: PropTypes.string,
  sortDir: PropTypes.oneOf(['asc', 'desc', 'none']),
  handleItemClick: PropTypes.func,
  firstColumn: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string
  }).isRequired
};

export default SmartStudentReportCoursesTable;
