import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './TablePagination.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Button from '../Button/Button';

function TablePagination({
  page,
  rowsPerPage,
  totalNumberOfEntries,
  onPageChangedHandler,
  onClickPrevHandler,
  onClickNextHandler,
  onClickBackToTopHandler
}) {
  const [currentPage, setCurrentPage] = useState(page);
  const [previousPage, setPreviousPage] = useState(page);
  /* hideLeftArrow is necessarry due to the fact that arrow transition of the first page doesn't work properly without it on first render.
  More exactly, arrow transition in the case of first page, must be disabled only and only at first render of the component. */
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const {
    tablePaginationWrapper,
    tablePaginationRow,
    tablePaginationColStart,
    tablePaginationColEnd,
    tablePaginationBtnWrapper,
    tablePaginationBtn,
    tablePaginationChevron,
    tablePaginationDisplayPages,
    tablePaginationHideLeftBtn,
    tablePaginationHideRightBtn,
    tablePaginationShowLeftBtn,
    tablePaginationShowRightBtn,
    tablePaginationHideBtn,
    tablePaginationBackToTopBtn,
    tablePaginationBackToTopBtnIcon,
    tablePaginationBackToTopBtnContainer,
    tablePaginationNavContainer
  } = styles;

  const MAX_ROWS_PER_PAGE = 10;
  const noOfLastEntryOnPage =
    rowsPerPage < MAX_ROWS_PER_PAGE
      ? (page - 1) * rowsPerPage + MAX_ROWS_PER_PAGE
      : (page - 1) * rowsPerPage + rowsPerPage;
  const noOfPages = Math.ceil(totalNumberOfEntries / MAX_ROWS_PER_PAGE);
  // If page number is equal to the last number of pages i.e. we are on the last page, set the variable to the
  // total number of entries we want to display all of them, if we are not on the last page use the noOfLastEntryOnPage
  // variable.
  const lastPageEnd = page === noOfPages ? totalNumberOfEntries : noOfLastEntryOnPage;
  const from = rowsPerPage < MAX_ROWS_PER_PAGE ? (page - 1) * MAX_ROWS_PER_PAGE + 1 : (page - 1) * rowsPerPage + 1;
  const to = totalNumberOfEntries < MAX_ROWS_PER_PAGE ? totalNumberOfEntries : lastPageEnd;

  useEffect(() => {
    setCurrentPage(1);
  }, [totalNumberOfEntries]);

  useEffect(() => {
    if (currentPage === '') {
      return;
    }
    onPageChangedHandler(currentPage);
  }, [currentPage]);

  const prevPageHandler = () => {
    setHideLeftArrow(false);
    setPreviousPage(currentPage);
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onClickPrevHandler();
    }
  };

  const nextPageHandler = () => {
    setHideLeftArrow(false);
    setPreviousPage(currentPage);
    if (currentPage < noOfPages) {
      setCurrentPage(currentPage + 1);
      onClickNextHandler();
    }
  };

  const onInputChangeHandler = e => {
    setHideLeftArrow(false);

    if (e.target.value === '') {
      setCurrentPage(page);
    }

    const value = parseInt(e.target.value, 10);

    if (value >= 1 && value <= noOfPages) {
      setPreviousPage(currentPage);
      setCurrentPage(value);
    } else if (value < 1) {
      setPreviousPage(currentPage);
      setCurrentPage(1);
    } else if (value > noOfPages) {
      setPreviousPage(currentPage);
      setCurrentPage(noOfPages);
    } else {
      setPreviousPage(currentPage);
      setCurrentPage('');
    }
  };

  const onInputBlurHandler = () => {
    if (currentPage === '') {
      setPreviousPage(currentPage);
      setCurrentPage(page);
    }
  };

  const onKeyPressHandler = e => {
    if (e.key === 'Enter') {
      setPreviousPage(currentPage);
      setCurrentPage(page);
    }
  };

  const pageNavRenderer = () => (
    <>
      <div className={tablePaginationNavContainer}>
        <span className={classnames(tablePaginationBtnWrapper, { [`${tablePaginationHideBtn}`]: hideLeftArrow })}>
          <button
            type="button"
            className={classnames(tablePaginationBtn, {
              [`${tablePaginationHideLeftBtn}`]: currentPage === 1,
              [`${tablePaginationShowLeftBtn}`]: currentPage === 2 && previousPage === 1
            })}
            onClick={() => prevPageHandler()}
          >
            <SVGIcon glyph={GLYPHS.ICON_LEFT} className={tablePaginationChevron} fill="#575757" />
          </button>
        </span>
        <span className={tablePaginationDisplayPages}>
          <input
            type="number"
            value={currentPage}
            onKeyPress={onKeyPressHandler}
            onChange={onInputChangeHandler}
            onBlur={onInputBlurHandler}
            onFocus={() => {
              setCurrentPage('');
            }}
          />
          / {noOfPages}
        </span>
        <span className={tablePaginationBtnWrapper}>
          <button
            type="button"
            className={classnames(tablePaginationBtn, {
              [`${tablePaginationHideRightBtn}`]: currentPage === noOfPages,
              [`${tablePaginationShowRightBtn}`]: currentPage === noOfPages - 1 && previousPage === noOfPages
            })}
            onClick={() => nextPageHandler()}
          >
            <SVGIcon glyph={GLYPHS.ICON_RIGHT} className={tablePaginationChevron} fill="#575757" />
          </button>
        </span>
      </div>
      <div className={tablePaginationBackToTopBtnContainer}>
        <Button
          type="GHOST"
          customClassName={tablePaginationBackToTopBtn}
          glyph={GLYPHS.ICON_ARROW_LEFT}
          glyphClassName={tablePaginationBackToTopBtnIcon}
          onClick={onClickBackToTopHandler}
        />
      </div>
    </>
  );

  return (
    <div className={tablePaginationWrapper}>
      <div className={tablePaginationRow}>
        <div className={tablePaginationColStart}>
          <span>
            {from} - {to} of {totalNumberOfEntries}
          </span>
        </div>
        <div className={tablePaginationColEnd}>{pageNavRenderer()}</div>
      </div>
    </div>
  );
}

TablePagination.propTypes = {
  totalNumberOfEntries: PropTypes.number.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChangedHandler: PropTypes.func.isRequired,
  onClickPrevHandler: PropTypes.func,
  onClickNextHandler: PropTypes.func,
  onClickBackToTopHandler: PropTypes.func
};

TablePagination.defaultProps = {
  page: 1,
  rowsPerPage: 10,
  onClickPrevHandler: () => 0,
  onClickNextHandler: () => 0
};

export default TablePagination;
