import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SmartDropdown.scss';
import { SVGIcon } from '..';
import { GLYPHS } from '../SVGIcon/SVGIcon';

const SmartDropdown = ({ filterText, options, dropdownPosition, value, handleSetValueAction }) => {
  const {
    headerLeft,
    headerRight,
    filterIcon,
    wrapper,
    dropdown,
    dropdownOption,
    activeOption,
    inactiveOption,
    checkIconWrapper,
    open,
    closed,
    dropdownRight
  } = styles;
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  // Set classes based on the dropdown position
  const headerClass = dropdownPosition === 'left' ? headerLeft : headerRight;
  const dropdownClass = classnames(dropdown, {
    [`${dropdownRight}`]: dropdownPosition === 'right',
    [`${open}`]: isDropdownOpened,
    [`${closed}`]: !isDropdownOpened
  });

  /**
   * Handle the menu option click
   */
  const handleDropdownOptionClick = option => {
    if (option.id !== (value.id ? value.id : value)) {
      handleSetValueAction(option);
    }
    setIsDropdownOpened(!isDropdownOpened);
  };

  /**
   * Render the options for the dropdown menu
   */
  const renderDropdownOptions = () =>
    options.map((option, index) => (
      <div className={dropdownOption} key={index} onClick={() => handleDropdownOptionClick(option)} aria-hidden="true">
        <div className={checkIconWrapper}>
          {option.id === (value.id ? value.id : value) && <SVGIcon glyph={GLYPHS.ICON_CHECK} />}
        </div>
        <p className={option.id === (value.id ? value.id : value) ? activeOption : inactiveOption}>{option.label}</p>
      </div>
    ));

  const selectedOption = options.find(option => option.id === (value.id ? value.id : value));

  return (
    <div className={wrapper} onMouseLeave={() => setIsDropdownOpened(false)}>
      <div
        className={headerClass}
        onClick={() => setIsDropdownOpened(!isDropdownOpened)}
        onMouseEnter={() => setIsDropdownOpened(true)}
        aria-hidden="true"
      >
        <div>{filterText}:</div>
        <div>{selectedOption.label}</div>
        <div>
          <SVGIcon className={filterIcon} glyph={GLYPHS.CHEVRON_DOWN} />
        </div>
      </div>
      <div className={dropdownClass}>{renderDropdownOptions()}</div>
    </div>
  );
};

SmartDropdown.defaultProps = {
  dropdownPosition: 'left'
};

SmartDropdown.propTypes = {
  filterText: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ]).isRequired,
  handleSetValueAction: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  dropdownPosition: PropTypes.oneOf(['left', 'right'])
};

export default SmartDropdown;
