import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartSwitch.scss';

const SmartSwitch = ({ value, checkedColor, uncheckedColor, handleValueChangedActions }) => {
  const { wrapper, container, input, slider, handler } = styles;
  return (
    <div className={wrapper}>
      Ascending
      <label htmlFor="smartSwitch" className={container}>
        <input
          type="checkbox"
          id="smartSwitch"
          checked={value}
          className={input}
          onChange={() => handleValueChangedActions(!value)}
        />
        <span className={slider} style={value ? { background: checkedColor } : { background: uncheckedColor }} />
        <span className={handler} />
      </label>
      Descending
    </div>
  );
};

SmartSwitch.propTypes = {
  value: PropTypes.bool,
  checkedColor: PropTypes.string,
  uncheckedColor: PropTypes.string,
  handleValueChangedActions: PropTypes.func
};

SmartSwitch.defaultProps = {
  value: true,
  checkedColor: '#10749e',
  uncheckedColor: '#10749e',
  handleValueChangedActions: () => {}
};

export default SmartSwitch;
