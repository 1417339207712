const getItemSubContentType = task => {
  switch (task.subContentType) {
    case 'n/a':
      return 'nonMarked';
    case 'auto':
      return 'autoMarked';
    case 'manually':
      return 'manualMarked';
    default:
      return 'autoMarked';
  }
};

const getItemContentType = task => {
  switch (task.contentType) {
    case 'Next steps':
      return 'levelup';
    case 'Assignment':
      return 'assessment';
    case 'Reactivates':
      return 'reactivate';
    case 'Other':
      return getItemSubContentType(task);
    default:
      return 'assessment';
  }
};

export default getItemContentType;
