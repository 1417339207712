import React from 'react';
import PropTypes from 'prop-types';

import styles from './NavTabs.scss';
import NavTabItem from './NavTabItem';

function NavTabs({ items, activeItemKey, expandedItemKey, onSelectedTab, onClickDetails }) {
  const selectTabHandler = tabId => {
    onSelectedTab(tabId);
  };

  const clickDetailsHandler = (tabId, tabExpandedValue) => {
    onClickDetails(tabId, tabExpandedValue);
  };

  const navTabItemRendered = navTabsItems =>
    navTabsItems.map((item, index) => (
      <NavTabItem
        key={item.id}
        item={item}
        activeItemKey={!activeItemKey && index === 0 ? items[index].id : activeItemKey}
        expandedItemKey={expandedItemKey}
        onClickDetails={(tabItemId, expanded) => clickDetailsHandler(tabItemId, expanded)}
        onSelectedTab={tabItemId => selectTabHandler(tabItemId)}
      />
    ));

  return items.length > 0 && <ul className={styles.tabsContainer}>{navTabItemRendered(items)}</ul>;
}

NavTabs.propTypes = {
  items: PropTypes.array.isRequired,
  onSelectedTab: PropTypes.func,
  onClickDetails: PropTypes.func,
  activeItemKey: PropTypes.string,
  expandedItemKey: PropTypes.string
};

export default NavTabs;
