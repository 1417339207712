import React, { useEffect, useRef, useState } from 'react';
import styles from './SmartStudentReportingCourses.scss';
import tree from './mocks.json';
import SmartStudentReportingCoursesStatisticsArea from './SmartStudentReportingCoursesStatisticsArea/SmartStudentReportingCoursesStatisticsArea';
import SmartStudentReportCoursesTableContainer from './SmartStudentReportCoursesTable/SmartStudentReportCoursesTableContainer';
import SmartLoadingSpinner from '../SmartLoadingSpinner/SmartLoadingSpinner';

const SmartStudentReportingCourses = () => {
  const { SmartStudentReportingCoursesWrapper, SmartStudentReportingCoursesTableWrapper } = styles;

  const pageRef = useRef();
  const [activeCourseId, setActiveCourseId] = useState(1);
  const [activeCourse, setActiveCourse] = useState({ name: '', children: [], id: 0, contentType: '' });
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [memoryStrength, setMemoryStrength] = useState(0);
  const [dseLevels, setDseLevels] = useState([
    { label: 'Developing', percentage: 50, color: '#ffee00' },
    { label: 'Secure', percentage: 33, color: '#c55cfc' },
    { label: 'Extending', percentage: 17, color: '#c9fd84' }
  ]);

  const findRecursively = (source = []) => {
    for (let i = 0; i < source.length; i += 1) {
      const result = findRecursively(source[i].children);

      if (result !== undefined) {
        return result;
      }
    }

    return source.find(c => c.id === activeCourseId);
  };

  useEffect(() => {
    setActiveCourse(findRecursively(tree));
  }, [activeCourseId]);

  useEffect(() => {
    setQuestionsAnswered(tree[0].questionsAnswered);
    setMemoryStrength(tree[0].memoryStrength);
    setDseLevels([
      { label: 'Developing', percentage: tree[0].developing, color: '#fe0' },
      { label: 'Secure', percentage: tree[0].secure, color: '#b0e501' },
      { label: 'Extending', percentage: tree[0].extending, color: '#c55cfc' }
    ]);
  }, []);

  const onClickBackToTopHandler = () => {
    const currentRef = pageRef.current || null;

    if (currentRef !== null) {
      currentRef.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderTable = () => {
    if (!activeCourse || activeCourse.children.length === 0) {
      return <SmartLoadingSpinner />;
    }

    return (
      <SmartStudentReportCoursesTableContainer
        items={activeCourse.children}
        firstColumn={{ key: 'name', label: activeCourse.contentType }}
        handleItemClick={itemId => setActiveCourseId(itemId)}
        handleBackToTopClick={onClickBackToTopHandler}
      />
    );
  };

  return (
    <div ref={pageRef} className={SmartStudentReportingCoursesWrapper}>
      <div>
        <SmartStudentReportingCoursesStatisticsArea
          tree={tree}
          activeItemId={activeCourseId}
          setActiveItemId={setActiveCourseId}
          dseLevels={dseLevels}
          questionsAnswered={questionsAnswered}
          memoryStrength={memoryStrength}
        />
      </div>
      <div className={SmartStudentReportingCoursesTableWrapper}>{renderTable()}</div>
    </div>
  );
};

export default SmartStudentReportingCourses;
