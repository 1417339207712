import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GLYPHS } from '../../../SVGIcon/SVGIcon';
import styles from './SmartOverlayPanelTableSortAndFilter.scss';
import SmartOverlayPanel from '../../../SmartOverlayPanel/SmartOverlayPanel';
import SmartContent from '../../../SmartContent/SmartContent';
import SmartCard from '../../../SmartCard/SmartCard';
import SmartAccordion from '../../../SmartAccordion/SmartAccordion';
import SmartRadioInputGroup from '../../../SmartRadioInputGroup/SmartRadioInputGroup';
import SmartSwitch from '../../../SmartSwitch/SmartSwitch';
import SmartPinkDot from '../../../SmartPinkDot/SmartPinkDot';

const SmartOverlayPanelTableSortAndFilter = ({ isOpen, handleClose, animation, filters }) => {
  const {
    filterValues,
    selectedFilterValues,
    displayPinkDot,
    sortDir,
    handleSetSortDir,
    handleFilterValueChange,
    handleSortValueChange,
    handleClearFilters
  } = filters;

  const [switchValue, setSwitchValue] = useState(sortDir !== 'asc');

  const { sortAndFilterRadio, sortAndFilterSwitch, clearFilterContainer, clearFilterText, clearFilterLink } = styles;

  useEffect(() => {
    handleSetSortDir(switchValue ? 'desc' : 'asc');
  }, [switchValue, selectedFilterValues]);

  return (
    <SmartOverlayPanel isOpen={isOpen} handleClose={handleClose} animation={animation}>
      <SmartContent
        contentOverData={{ title: 'Sort and Filter', iconRight: GLYPHS.ICON_CLOSE }}
        contentUnderData={{
          content: (
            <>
              <SmartCard noPadding>
                <SmartAccordion headerText={<b>Sort by:</b>}>
                  <>
                    <div className={sortAndFilterSwitch}>
                      <SmartSwitch value={switchValue} handleValueChangedActions={value => setSwitchValue(value)} />
                    </div>
                    <div className={sortAndFilterRadio}>
                      <SmartRadioInputGroup
                        items={filterValues.sortBy}
                        value={selectedFilterValues.selectedSortBy}
                        onValueChangedHandler={handleSortValueChange}
                      />
                    </div>
                  </>
                </SmartAccordion>
              </SmartCard>
              <SmartCard noPadding>
                <div className={clearFilterContainer}>
                  <div className={clearFilterText}>
                    <b>Filter:</b>
                  </div>
                  <div className={clearFilterLink}>
                    <a onClick={handleClearFilters} aria-hidden="true">
                      Clear all
                    </a>
                  </div>
                </div>
              </SmartCard>
              <SmartCard noPadding>
                <SmartAccordion
                  headerText="Content type"
                  headerComponent={displayPinkDot.contentType ? <SmartPinkDot /> : null}
                >
                  <div className={sortAndFilterRadio}>
                    <SmartRadioInputGroup
                      items={filterValues.contentType}
                      value={selectedFilterValues.selectedContentType}
                      onValueChangedHandler={handleFilterValueChange}
                    />
                  </div>
                </SmartAccordion>
              </SmartCard>
              <SmartCard noPadding>
                <SmartAccordion headerText="Subject" headerComponent={displayPinkDot.subject ? <SmartPinkDot /> : null}>
                  <div className={sortAndFilterRadio}>
                    <SmartRadioInputGroup
                      items={filterValues.subject}
                      value={selectedFilterValues.selectedSubject}
                      onValueChangedHandler={handleFilterValueChange}
                    />
                  </div>
                </SmartAccordion>
              </SmartCard>
              <SmartCard noPadding>
                <SmartAccordion
                  headerText="Assigned by"
                  headerComponent={displayPinkDot.assignedBy ? <SmartPinkDot /> : null}
                >
                  <div className={sortAndFilterRadio}>
                    <SmartRadioInputGroup
                      items={filterValues.assignedBy}
                      value={selectedFilterValues.selectedAssignedBy}
                      onValueChangedHandler={handleFilterValueChange}
                    />
                  </div>
                </SmartAccordion>
              </SmartCard>
            </>
          )
        }}
        handleRightBtn={handleClose}
      />
    </SmartOverlayPanel>
  );
};

SmartOverlayPanelTableSortAndFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  animation: PropTypes.oneOf(['left', 'right']),
  filters: PropTypes.object
};

export default SmartOverlayPanelTableSortAndFilter;
