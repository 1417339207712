import React from 'react';
import SmartOverlayPanel from '../SmartOverlayPanel/SmartOverlayPanel';

const SmartDemoPage = () => {
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);

  const handleButtonClick = () => {
    console.log('[DEMO] The button was clicked...');
    window.postMessage({ type: 'NOTIFICATION', eventName: 'MODAL_INTERACTION_START' }, '*');
    console.log('[DEMO] Message to start the interraction was sent...');
    setIsOverlayOpen(true);
    console.log('[DEMO] The overlay is now opened...');
  };

  const handleOverlayClose = () => {
    setIsOverlayOpen(!isOverlayOpen);
    console.log('[DEMO] The overlay is now closed...');
    window.postMessage({ type: 'NOTIFICATION', eventName: 'MODAL_INTERACTION_END' }, '*');
    console.log('[DEMO] Message to end the interraction was sent.');
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <h1>Demo Page SSO - for testing purposes only</h1>
        <p>Click the button the send an event and trigger the overlay</p>
        <div>
          <button
            type="button"
            onClick={handleButtonClick}
            style={{ border: '1px solid black', padding: '10px 20px', margin: '20px auto' }}
          >
            Click here
          </button>
        </div>
        <p>
          <i>To close the overlay, you just need to click outside.</i>
        </p>
      </div>
      <SmartOverlayPanel isOpen={isOverlayOpen} handleClose={handleOverlayClose} />
    </>
  );
};

export default SmartDemoPage;
