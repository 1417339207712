import React from 'react';
import PropTypes from 'prop-types';
import styles from './SecondLevelTabsItem.scss';

function SecondLevelTabsItem({ item, activeItemKey, handleClickItem }) {
  const { label, key } = item;
  const { secondLevelTabsItem, secondLevelTabsItemLabel, secondLevelTabsItemLabelActive } = styles;

  const itemClass = activeItemKey === key ? secondLevelTabsItemLabelActive : secondLevelTabsItemLabel;

  return (
    <div className={secondLevelTabsItem} onClick={() => handleClickItem(key)} role="button" tabIndex={0} aria-hidden>
      <span className={itemClass}>{label}</span>
    </div>
  );
}

SecondLevelTabsItem.propTypes = {
  // item - Tab item that will be rendered.
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  }).isRequired,
  //   activeItemKey - the items that is active in the list (not required, by default the first one is active)
  activeItemKey: PropTypes.string,
  //   handleClickItem - method that handles item click
  handleClickItem: PropTypes.func.isRequired
};

export default SecondLevelTabsItem;
