const extractFilterOptionsFromDataset = (dataset, field) => {
  let fieldName = field;
  if (field === 'contentType') {
    fieldName = 'taskType';
  }
  const result = {};
  dataset.forEach(item => {
    result[item[field]] = 1;
  });
  return [
    { id: `All`, label: 'All', name: fieldName },
    ...Object.keys(result).map(item => ({ id: `${item}`, label: item, name: fieldName }))
  ];
};

export default extractFilterOptionsFromDataset;
