import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartList.scss';

const SmartList = ({ children }) => {
  const { smartListWrapper } = styles;
  return (
    <>
      <div className={smartListWrapper}>{children}</div>
    </>
  );
};

SmartList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default SmartList;
