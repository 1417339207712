import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SmartTableHeaderCell.scss';

const SmartTableHeaderCell = ({ children, colSpan, align, order, tagName, handleClick, borderType, hasPadding }) => {
  const {
    smartTableHeaderCell,
    smartTableHeaderCellNoPadding,
    smartTableHeaderCellTextLeft,
    smartTableHeaderCellTextCenter,
    smartTableHeaderCellTextRight,
    smartTableHeaderCellFirst,
    smartTableHeaderCellLast,
    smartTableHeaderCellDashed,
    smartTableHeaderCellLastDashed,
    smartTableHeaderCellAction
  } = styles;

  const HtmlTag = tagName;

  const headerCellClass = classnames(smartTableHeaderCell, {
    [`${smartTableHeaderCellNoPadding}`]: !hasPadding,
    [`${smartTableHeaderCellAction}`]: handleClick !== undefined,
    [`${smartTableHeaderCellTextLeft}`]: align === 'left',
    [`${smartTableHeaderCellTextCenter}`]: align === 'center',
    [`${smartTableHeaderCellTextRight}`]: align === 'right',
    [`${smartTableHeaderCellFirst}`]: order === 'first',
    [`${smartTableHeaderCellLast}`]: order === 'last',
    [`${smartTableHeaderCellDashed}`]: borderType === 'dashed' && order !== 'first',
    [`${smartTableHeaderCellLastDashed}`]: borderType === 'dashed' && order === 'last'
  });

  if (colSpan) {
    return (
      <HtmlTag className={headerCellClass} colSpan={colSpan} {...(handleClick && { onClick: () => handleClick() })}>
        {children}
      </HtmlTag>
    );
  }

  return (
    <HtmlTag className={headerCellClass} {...(handleClick && { onClick: () => handleClick() })}>
      {children}
    </HtmlTag>
  );
};

SmartTableHeaderCell.defaultProps = {
  align: 'left',
  order: 'between',
  tagName: 'th',
  borderType: 'normal',
  hasPadding: true
};

SmartTableHeaderCell.propTypes = {
  tagName: PropTypes.oneOf(['div', 'th']),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  colSpan: PropTypes.number,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  order: PropTypes.oneOf(['first', 'between', 'last']),
  borderType: PropTypes.oneOf(['dashed', 'normal']),
  handleClick: PropTypes.func,
  hasPadding: PropTypes.bool
};

export default SmartTableHeaderCell;
