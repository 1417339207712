import React from 'react';
import PropTypes from 'prop-types';
import { hasValueNotApplicable, hasValueAwaiting } from '../../utils/string';
import SmartPercentage from '../SmartPercentage/SmartPercentage';

const SmartTableReportingPercentage = ({ percentage }) => {
  if (hasValueNotApplicable(percentage)) {
    return '';
  } else if (hasValueAwaiting(percentage)) {
    return 'Awaiting';
  }

  return <SmartPercentage percentage={percentage} />;
};

SmartTableReportingPercentage.propTypes = {
  percentage: PropTypes.string
};

export default SmartTableReportingPercentage;
