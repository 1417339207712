import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SmartOverlayPanel from '../../../SmartOverlayPanel/SmartOverlayPanel';
import SmartContent from '../../../SmartContent/SmartContent';
import SVGIcon, { GLYPHS } from '../../../SVGIcon/SVGIcon';
import SmartCard from '../../../SmartCard/SmartCard';
import styles from './SmartOverlayPanelTableLevelUp.scss';
import SmartListItem from '../../../SmartList/SmartListItem/SmartListItem';
import SmartOverlayPanelQuestions from '../SmartOverlayPanelQuestions/SmartOverlayPanelQuestions';
import { hasValueNotApplicable } from '../../../../utils/string';
import { canRenderDseBand, renderDseBand, renderMarkRow } from '../utils';

const SmartOverlayPanelTableLevelUp = ({ isOpen, handleClose, animation, levelup }) => {
  const { wrapper, wrapperLast, sectionTitle, icon, iconWrapper } = styles;

  const [areAnswersOpen, setAreAnswersOpen] = useState(false);

  useEffect(() => () => setAreAnswersOpen(false), [isOpen]);

  const printIUnderstandValue = () => {
    if (levelup) {
      if (levelup.understood) {
        return (
          <div className={iconWrapper}>
            Yes
            <SVGIcon className={icon} glyph={GLYPHS.ICON_THUMBS_UP} />
          </div>
        );
      }

      return (
        <div className={iconWrapper}>
          No <SVGIcon className={icon} glyph={GLYPHS.ICON_THUMBS_DOWN} />
        </div>
      );
    }

    return <></>;
  };

  if (!levelup) {
    return <></>;
  }

  const { markLabel, percentageLabel, dseLabel, dateCompleted, sidePanelInfo } = levelup;

  return (
    <SmartOverlayPanel isOpen={isOpen} handleClose={handleClose} animation={animation}>
      <SmartContent
        contentOverData={{
          title: `[${levelup.task}]`,
          iconRight: GLYPHS.ICON_CLOSE
        }}
        contentUnderData={{
          content: (
            <>
              <SmartCard noPadding hasBorder>
                <div className={wrapper}>
                  <div className={sectionTitle}>Results</div>
                  {canRenderDseBand(dseLabel) && <SmartListItem label="DSE band:" value={renderDseBand(dseLabel)} />}
                  <SmartListItem label="Date Completed:" value={moment.unix(dateCompleted).format('DD/M/YY')} />
                  <SmartListItem label="I understand:" value={printIUnderstandValue()} />
                  {!hasValueNotApplicable(markLabel) && (
                    <SmartListItem
                      label="Mark:"
                      value={renderMarkRow(markLabel, percentageLabel)}
                      extraValue={
                        sidePanelInfo.questions.length > 0 && (
                          <a onClick={() => setAreAnswersOpen(!areAnswersOpen)} aria-hidden="true">
                            {areAnswersOpen ? 'Hide your answers' : 'Review your answers'}
                          </a>
                        )
                      }
                    />
                  )}
                  {areAnswersOpen && <SmartOverlayPanelQuestions questions={sidePanelInfo.questions} />}
                </div>
              </SmartCard>
              <SmartCard noPadding>
                <div className={wrapperLast}>
                  <div className={sectionTitle}>About</div>
                  <SmartListItem label="Subject:" value={levelup.subject} />
                  <SmartListItem label="Theme:" value={levelup.sidePanelInfo.theme} />
                  <SmartListItem label="Content type:" value={levelup.contentType} />
                </div>
              </SmartCard>
            </>
          )
        }}
        handleRightBtn={handleClose}
      />
    </SmartOverlayPanel>
  );
};

SmartOverlayPanelTableLevelUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  animation: PropTypes.oneOf(['left', 'right']),
  levelup: PropTypes.object
};

export default SmartOverlayPanelTableLevelUp;
