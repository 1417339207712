import React from 'react';
import PropTypes from 'prop-types';
import SmartRadioInput from './SmartRadioInput/SmartRadioInput';

const SmartRadioInputGroup = ({ items, value, onValueChangedHandler }) => {
  const handleValueChanged = item => {
    if (item.id !== (value.id ? value.id : value)) {
      onValueChangedHandler(item);
    }
  };

  return (
    <>
      {items.map(item => (
        <SmartRadioInput
          key={item.id}
          item={item}
          isChecked={item.id === (value.id ? value.id : value)}
          onChangeHandler={() => handleValueChanged(item)}
        />
      ))}
    </>
  );
};

SmartRadioInputGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })]),
  onValueChangedHandler: PropTypes.func
};

export default SmartRadioInputGroup;
