import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SmartOverlayPanel.scss';

const SmartOverlayPanel = ({ isOpen, handleClose, children, animation }) => {
  const { overlay, main, mainRight, enabledOverlay, enabledMain, enabledMainRight } = styles;

  const mainClass = classnames({
    [`${main}`]: animation === 'left',
    [`${mainRight}`]: animation === 'right',
    [`${enabledMain}`]: isOpen && animation === 'left',
    [`${enabledMainRight}`]: isOpen && animation === 'right'
  });

  const overlayClass = classnames(overlay, {
    [`${enabledOverlay}`]: isOpen
  });

  return (
    <>
      <div className={mainClass}>{children}</div>
      <div className={overlayClass} onClick={handleClose} aria-hidden="true" />
    </>
  );
};

SmartOverlayPanel.defaultProps = {
  animation: 'left'
};

SmartOverlayPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.element,
  animation: PropTypes.oneOf(['left', 'right'])
};

export default SmartOverlayPanel;
