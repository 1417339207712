import React from 'react';
import PropTypes from 'prop-types';
import { hasValueNotApplicable, hasValueAwaiting, capitalize } from '../../utils/string';
import SmartTableReportingMark from '../SmartTableReportingMark/SmartTableReportingMark';
import SmartTableReportingPercentage from '../SmartTableReportingPercentage/SmartTableReportingPercentage';

const SmartOverlayPanelTableMarkRow = ({ mark, percentage }) => {
  if (hasValueNotApplicable(mark)) {
    return '';
  }

  if (hasValueAwaiting(mark)) {
    return capitalize(mark);
  }

  /** @todo remove the styling using spaces */
  return (
    <>
      <SmartTableReportingMark mark={mark} /> <SmartTableReportingPercentage percentage={percentage} />
    </>
  );
};

SmartOverlayPanelTableMarkRow.propTypes = {
  mark: PropTypes.string,
  percentage: PropTypes.string
};

export default SmartOverlayPanelTableMarkRow;
