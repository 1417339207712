import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GLYPHS } from '../../../../../components/SVGIcon/SVGIcon';
import { SVGIcon } from '../../../../../components';
import SmartPinkDot from '../../../../../components/SmartPinkDot/SmartPinkDot';
import { FiltersContext, SortingContext } from '../../ContextProviders';
import styles from './FiltersAreaMobile.scss';
import SmartOverlayPanel from '../../../../../components/SmartOverlayPanel/SmartOverlayPanel';
import SmartContent from '../../../../../components/SmartContent/SmartContent';
import SmartCard from '../../../../../components/SmartCard/SmartCard';
import SmartAccordion from '../../../../../components/SmartAccordion/SmartAccordion';
import SmartSwitch from '../../../../../components/SmartSwitch/SmartSwitch';
import SmartRadioInputGroup from '../../../../../components/SmartRadioInputGroup/SmartRadioInputGroup';

function FiltersAreaMobile({ animation }) {
  const filtersContext = useContext(FiltersContext);
  const sorting = useContext(SortingContext);

  const { column, direction, setSortingDirection, options: sortingOptions, setSortingColumn } = sorting;
  const {
    isSideBarVisible,
    clearFilters,
    openSideBar,
    closeSideBar,
    isFilterActive,
    values,
    options: filterOptions,
    setFilterValue,
    activeFilters
  } = filtersContext;

  const {
    filterMobile,
    filterMobileContainer,
    filterMobileDot,
    filterIcon,
    sortAndFilterSwitch,
    sortAndFilterRadio,
    clearFilterContainer,
    clearFilterText,
    clearFilterLink
  } = styles;
  const handleItemClick = () => {
    openSideBar();
  };

  const handleFilterValueChange = value => setFilterValue(value.name, value.label);
  const handleSortValueChange = value => setSortingColumn(value.id);
  return (
    <>
      <div className={filterMobile}>
        <div className={filterMobileContainer} aria-hidden="true" onClick={handleItemClick}>
          {isFilterActive && (
            <div className={filterMobileDot}>
              <SmartPinkDot />
            </div>
          )}
          <div>
            <SVGIcon glyph={GLYPHS.ICON_FILTER} className={filterIcon} />
          </div>
        </div>
      </div>

      <SmartOverlayPanel isOpen={isSideBarVisible} handleClose={closeSideBar} animation={animation}>
        <SmartContent
          contentOverData={{ title: 'Sort and Filter', iconRight: GLYPHS.ICON_CLOSE }}
          contentUnderData={{
            content: (
              <>
                <SmartCard noPadding>
                  <SmartAccordion headerText={<b>Sort by:</b>}>
                    <>
                      <div className={sortAndFilterSwitch}>
                        <SmartSwitch
                          value={direction === 'desc'}
                          handleValueChangedActions={value => setSortingDirection(value)}
                        />
                      </div>
                      <div className={sortAndFilterRadio}>
                        <SmartRadioInputGroup
                          items={sortingOptions}
                          value={column}
                          onValueChangedHandler={handleSortValueChange}
                        />
                      </div>
                    </>
                  </SmartAccordion>
                </SmartCard>
                <SmartCard noPadding>
                  <div className={clearFilterContainer}>
                    <div className={clearFilterText}>
                      <b>Filter:</b>
                    </div>
                    <div className={clearFilterLink}>
                      <a onClick={clearFilters} aria-hidden="true">
                        Clear all
                      </a>
                    </div>
                  </div>
                </SmartCard>
                <SmartCard noPadding>
                  <SmartAccordion
                    headerText="Content type"
                    headerComponent={activeFilters.contentType ? <SmartPinkDot /> : null}
                  >
                    <div className={sortAndFilterRadio}>
                      <SmartRadioInputGroup
                        items={filterOptions.taskType}
                        value={values.taskType}
                        onValueChangedHandler={handleFilterValueChange}
                      />
                    </div>
                  </SmartAccordion>
                </SmartCard>
                <SmartCard noPadding>
                  <SmartAccordion
                    headerText="Subject"
                    headerComponent={activeFilters.subject ? <SmartPinkDot /> : null}
                  >
                    <div className={sortAndFilterRadio}>
                      <SmartRadioInputGroup
                        items={filterOptions.subject}
                        value={values.subject}
                        onValueChangedHandler={handleFilterValueChange}
                      />
                    </div>
                  </SmartAccordion>
                </SmartCard>
                <SmartCard noPadding>
                  <SmartAccordion
                    headerText="Assigned by"
                    headerComponent={activeFilters.assignedBy ? <SmartPinkDot /> : null}
                  >
                    <div className={sortAndFilterRadio}>
                      <SmartRadioInputGroup
                        items={filterOptions.assignedBy}
                        value={values.assignedBy}
                        onValueChangedHandler={handleFilterValueChange}
                      />
                    </div>
                  </SmartAccordion>
                </SmartCard>
              </>
            )
          }}
          handleRightBtn={closeSideBar}
        />
      </SmartOverlayPanel>
    </>
  );
}

FiltersAreaMobile.propTypes = {
  animation: PropTypes.oneOf(['left', 'right'])
};

export default FiltersAreaMobile;
