/* eslint-disable import/no-import-module-exports */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'whatwg-fetch';

import LocalizedContentProvider from './language/LocalizedContentProvider';
import { prepareAuthRefreshListener } from './redux/sagas/preRender/auth/authRefreshHandler.saga';
import store from './redux/store';
import RouteManager from './structure/routeManagerSmart';
import './utils/browser/mobile';
import './styles/base/_index.scss';
import glossaries from './utils/cmsContent';
import './utils/dom/bodyClassChangeOnMousedownKeydown';
import './utils/dom/preventFocusOnHiddenElements';
import './utils/polyfill/console';
import prepareServiceWorker from './service-worker-utils.js';

prepareAuthRefreshListener();

// The DOM element present on the basic un-rendered HTML page.
const mountPoint = document.getElementById('mnt');

// Check if a service worker is required then install one otherwise remove any current ones
prepareServiceWorker('smart', navigator);

// do not try to use isDev to determine this - environmentSettings are not loaded at this point
// @ts-ignore
if (__PROD__) {
  // Basic production mode render. No hot reloading included.
  ReactDOM.render(
    <Provider store={store}>
      <LocalizedContentProvider localizedContent={glossaries}>
        <RouteManager />
      </LocalizedContentProvider>
    </Provider>,
    mountPoint
  );
} else {
  // Keep any RHL code within a __PROD__/__DEV__ if statement so that it is dropped in the prod builds

  // Include dev-only styles such as markup-warnings:
  // @ts-ignore
  require('./styles/dev-only/_index.scss'); // eslint-disable-line global-require, import/no-extraneous-dependencies
  // @ts-ignore
  require('./utils/dev-only/toggle-markup-warnings.js'); // eslint-disable-line global-require, import/no-extraneous-dependencies

  // The RHL AppContainer should wrap the top level component
  const AppContainer = require('react-hot-loader').AppContainer; // eslint-disable-line global-require, import/no-extraneous-dependencies

  // This is the initial render
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <LocalizedContentProvider localizedContent={glossaries}>
          <RouteManager />
        </LocalizedContentProvider>
      </Provider>
    </AppContainer>,
    mountPoint
  );

  /* global module */
  // This code accepts hot reloading event from webpack and re-renders the App
  if (module.hot) {
    module.hot.accept('./structure/routeManagerSmart.js', () => {
      // If you use Webpack 2 in ES modules mode, you can
      // use <RouteManager /> here rather than require() a <NextRouteManager />.
      const NextRouteManager = require('./structure/routeManagerSmart.js').default; // eslint-disable-line global-require

      ReactDOM.render(
        <AppContainer>
          <Provider store={store}>
            <LocalizedContentProvider localizedContent={glossaries}>
              <NextRouteManager />
            </LocalizedContentProvider>
          </Provider>
        </AppContainer>,
        mountPoint
      );
    });
  }
}
