import React from 'react';
import { Link } from 'react-router-dom';
import { groups } from '../../structure/SmartTeacherLayout/mocks.json';

const SmartTeacherReportingAllClassesCurriculum = () => (
  <div>
    All classes curriculum
    {groups.map(group => (
      <div key={group.id}>
        <Link to={`/dev/teacher-reporting/group/${group.id}/curriculum`}>{group.name} - curriculum</Link>
      </div>
    ))}
  </div>
);

export default SmartTeacherReportingAllClassesCurriculum;
