import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SmartTableCell.scss';

const SmartTableCell = ({ children, colSpan, align, order, borderType, tagName, width }) => {
  const {
    smartTableCell,
    smartTableCellTextLeft,
    smartTableCellTextCenter,
    smartTableCellTextRight,
    smartTableCellFirst,
    smartTableCellLast,
    smartTableCellDashed,
    smartTableCellLastDashed,
    smartTableCellInner
  } = styles;

  const cellClass = classnames(smartTableCell, {
    [`${smartTableCellTextLeft}`]: align === 'left',
    [`${smartTableCellTextCenter}`]: align === 'center',
    [`${smartTableCellTextRight}`]: align === 'right',
    [`${smartTableCellFirst}`]: order === 'first',
    [`${smartTableCellLast}`]: order === 'last',
    [`${smartTableCellDashed}`]: borderType === 'dashed' && order !== 'first',
    [`${smartTableCellLastDashed}`]: borderType === 'dashed' && order === 'last'
  });

  const HtmlTag = tagName;

  if (colSpan) {
    return (
      <HtmlTag colSpan={colSpan} className={cellClass}>
        <div style={{ width }} className={smartTableCellInner}>
          {children}
        </div>
      </HtmlTag>
    );
  }

  return (
    <HtmlTag className={cellClass}>
      <div style={{ width }} className={smartTableCellInner}>
        {children}
      </div>
    </HtmlTag>
  );
};

SmartTableCell.defaultProps = {
  align: 'left',
  order: 'between',
  borderType: 'normal',
  tagName: 'td'
};

SmartTableCell.propTypes = {
  tagName: PropTypes.oneOf(['div', 'th', 'td']),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.string]),
  colSpan: PropTypes.number,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  order: PropTypes.oneOf(['first', 'between', 'last']),
  borderType: PropTypes.oneOf(['dashed', 'normal']),
  // This is the width of the cell (for example: 200px)
  width: PropTypes.string
};

export default SmartTableCell;
