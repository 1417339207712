import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartStudentReportCoursesTableMobile.scss';
import SmartCard from '../../../SmartCard/SmartCard';

const SmartStudentsReportCoursesTableMobile = ({ items, handleItemClick }) => {
  const { Wrapper, Row, TitleColumn, EmptyColumn, DataColumn } = styles;

  const renderCourseEntries = entry => {
    const { children, id, name, developing, secure, extending, questionsAnswered, memoryStrength } = entry;
    return (
      <>
        <div className={Row}>
          <div className={TitleColumn}>
            {children !== undefined ? (
              <a onClick={() => handleItemClick(id, name)} aria-hidden="true">
                {name}
              </a>
            ) : (
              name
            )}
          </div>
          <div className={EmptyColumn} />
        </div>
        <div className={Row}>
          <div className={DataColumn}>Developing:</div>
          <div className={DataColumn}>
            {developing !== null ? (
              <>
                {developing}
                <sup>%</sup>
              </>
            ) : (
              <div>Awaiting</div>
            )}
          </div>
        </div>
        <div className={Row}>
          <div className={DataColumn}>Secure:</div>
          <div className={DataColumn}>
            {secure !== null ? (
              <>
                {secure}
                <sup>%</sup>
              </>
            ) : (
              <div>Awaiting</div>
            )}
          </div>
        </div>
        <div className={Row}>
          <div className={DataColumn}>Extending:</div>
          <div className={DataColumn}>
            {extending !== null ? (
              <>
                {extending}
                <sup>%</sup>
              </>
            ) : (
              <div>Awaiting</div>
            )}
          </div>
        </div>
        <div className={Row}>
          <div className={DataColumn}>Questions answered:</div>
          <div className={DataColumn}>{questionsAnswered !== null ? questionsAnswered : <div>Awaiting</div>}</div>
        </div>
        <div className={Row}>
          <div className={DataColumn}>Memory strength:</div>
          <div className={DataColumn}>
            {memoryStrength !== null ? (
              <>
                {memoryStrength}
                <sup>%</sup>
              </>
            ) : (
              <div>Awaiting</div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {items.map((item, index) => {
        let roundedBorder;

        if (index === 0 && items.length === 1) {
          roundedBorder = 'all';
        } else if (index === 0) {
          roundedBorder = 'top';
        } else if (index === items.length - 1) {
          roundedBorder = 'bottom';
        } else {
          roundedBorder = 'none';
        }

        return (
          <SmartCard noPadding hasBorder roundedBorder={roundedBorder} key={index}>
            <div className={Wrapper}>{renderCourseEntries(item)}</div>
          </SmartCard>
        );
      })}
    </>
  );
};

SmartStudentsReportCoursesTableMobile.propTypes = {
  items: PropTypes.array.isRequired,
  handleItemClick: PropTypes.func
};

export default SmartStudentsReportCoursesTableMobile;
