function subtractLight(color, amount) {
  const cc = parseInt(color, 16) - amount;
  const c = cc < 0 ? 0 : cc;
  return c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
}

export default function colorDarken(color, amount) {
  const newColor = color.indexOf('#') >= 0 ? color.substring(1, color.length) : color;
  const newAmount = parseInt((255 * amount) / 100, 10);
  return `#${subtractLight(newColor.substring(0, 2), newAmount)}${subtractLight(
    newColor.substring(2, 4),
    newAmount
  )}${subtractLight(newColor.substring(4, 6), newAmount)}`;
}
