import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SmartContentTitleBar.scss';

import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import useMediaQuery from '../../../utils/mediaQuery';

const SmartContentTitleBar = ({ applyShadow, titleContent, viewBox, handleRightBtn, handleLeftBtn }) => {
  const { container, shadow, icon, title } = styles;
  const isMobile = useMediaQuery('(max-width: 1023px)');

  /**
   * Truncate the text by number of characters
   */
  const truncateText = text => {
    const numberOfCharacters = isMobile ? 45 : 30;

    if (text.length > numberOfCharacters) {
      // It starts from 1 because the first char will be a square bracket
      return `[${text.substring(1, numberOfCharacters)}...]`;
    }

    return text;
  };

  return (
    <div className={classnames(container, { [`${shadow}`]: applyShadow })}>
      {titleContent.iconLeft && (
        <div
          aria-hidden="true"
          onClick={() => {
            handleLeftBtn(true);
          }}
          className={icon}
        >
          <SVGIcon glyph={titleContent.iconLeft} />
        </div>
      )}
      <div className={title}>{truncateText(titleContent.title)}</div>
      {titleContent.iconRight && (
        <div
          aria-hidden="true"
          onClick={() => {
            handleRightBtn(true);
          }}
          className={icon}
        >
          <SVGIcon glyph={GLYPHS.ICON_CLOSE} />
        </div>
      )}
    </div>
  );
};

SmartContentTitleBar.propTypes = {
  applyShadow: PropTypes.bool.isRequired,
  titleContent: PropTypes.shape({ iconLeft: PropTypes.string, iconRight: PropTypes.string, title: PropTypes.string }),
  handleRightBtn: PropTypes.func,
  handleLeftBtn: PropTypes.func,
  viewBox: PropTypes.string
};

SmartContentTitleBar.defaultProps = {
  viewBox: '0 0 0.5 1'
};

export default SmartContentTitleBar;
