import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TaskInfoSidebarContext } from '../ContextProviders';
import styles from './TaskInfoArea.scss';
import TaskInfoAreaAssessments from './TaskInfoAreaAssessments';
import TaskInfoAreaAutoManuallyMarked from './TaskInfoAreaAutoManuallyMarked';
import TaskInfoAreaLevelUp from './TaskInfoAreaLevelUp';
import TaskInfoAreaManuallyNonInteractive from './TaskInfoAreaManuallyNonInteractive';
import TaskInfoAreaNonMarkedItem from './TaskInfoAreaNonMarkedItem';
import TaskInfoAreaReactivates from './TaskInfoAreaReactivates';

function TaskInfoArea({ animation }) {
  const infoSidebar = useContext(TaskInfoSidebarContext);
  const type = infoSidebar.type;
  const data = infoSidebar.data;
  const isVisible = infoSidebar.isVisible;
  const handleClose = infoSidebar.hideSidebar;

  const renderOverlay = () => {
    switch (type) {
      case 'assessment':
        return (
          <TaskInfoAreaAssessments
            isOpen={isVisible}
            handleClose={handleClose}
            animation={animation}
            assessment={data}
          />
        );
      case 'autoMarked':
      case 'manualMarked':
        return (
          <TaskInfoAreaAutoManuallyMarked
            isOpen={isVisible}
            handleClose={handleClose}
            animation={animation}
            autoManuallyMarkedItem={data}
          />
        );
      case 'levelup':
        return (
          <TaskInfoAreaLevelUp isOpen={isVisible} handleClose={handleClose} animation={animation} levelup={data} />
        );
      case 'nonMarked':
        return (
          <TaskInfoAreaNonMarkedItem
            isOpen={isVisible}
            handleClose={handleClose}
            animation={animation}
            nonMarkedItem={data}
          />
        );
      case 'manualMarkedNI':
        return (
          <TaskInfoAreaManuallyNonInteractive
            isOpen={isVisible}
            handleClose={handleClose}
            animation={animation}
            manuallyMarkedNI={data}
          />
        );
      case 'reactivate':
        return (
          <TaskInfoAreaReactivates
            isOpen={isVisible}
            handleClose={handleClose}
            animation={animation}
            reactivateItem={data}
          />
        );
      default:
        console.info('No type has been provided for the overlay.');
        return <></>;
    }
  };

  return <div className={styles.taskInfoArea}>{renderOverlay()}</div>;
}

TaskInfoArea.defaultProps = {
  animation: 'left'
};

TaskInfoArea.propTypes = {
  animation: PropTypes.oneOf(['left', 'right'])
};

export default TaskInfoArea;
