import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartRadioInput.scss';

const SmartRadioInput = ({ item, isChecked, onChangeHandler }) => {
  const { wrapper, radioLabel } = styles;

  return (
    <div className={wrapper}>
      <input
        type="radio"
        id={`radioButton${item.id ? item.id : item}${item.name}`}
        checked={isChecked}
        onChange={() => onChangeHandler(item)}
      />
      <label htmlFor={`radioButton${item.id ? item.id : item}${item.name}`} className={radioLabel}>
        {item.label ? item.label : item}
      </label>
    </div>
  );
};

SmartRadioInput.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })]),
  isChecked: PropTypes.bool,
  onChangeHandler: PropTypes.func
};

export default SmartRadioInput;
