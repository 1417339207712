import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatisticsArea.scss';
import StatisticalLozenges from '../../../../components/StatisticalLozenges/StatisticalLozenges';

function StatisticsAreaDesktop({ items }) {
  const { lozenges, lozengesWrapper, lozengesItem } = styles;
  return (
    <div className={lozenges}>
      <div className={lozengesWrapper}>
        {items.map((item, index) => (
          <div key={index} className={lozengesItem}>
            <StatisticalLozenges icon={item.icon} text={item.number} />
            <span>{item.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

StatisticsAreaDesktop.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ icon: PropTypes.element, number: PropTypes.number, title: PropTypes.string })
  )
};

export default StatisticsAreaDesktop;
