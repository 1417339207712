import React from 'react';
import PropTypes from 'prop-types';

const SmartTableBody = ({ children }) => <tbody>{children}</tbody>;

SmartTableBody.defaultProps = {
  children: {}
};

SmartTableBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default SmartTableBody;
