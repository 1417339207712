import React from 'react';
import PropTypes from 'prop-types';
import SmartList from '../../../../components/SmartList/SmartList';
import SmartListItem from '../../../../components/SmartList/SmartListItem/SmartListItem';

function StatisticsAreaMobile({ items }) {
  return (
    <SmartList>
      {items.map((item, index) => (
        <SmartListItem
          key={index}
          className="smartList"
          label={item.icon}
          value={item.number}
          extraValue={item.title}
        />
      ))}
    </SmartList>
  );
}

StatisticsAreaMobile.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ icon: PropTypes.element, number: PropTypes.number, title: PropTypes.string })
  )
};

export default StatisticsAreaMobile;
