import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SmartAccordion.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

const SmartAccordion = ({ headerText, headerComponent, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { wrapper, header, content, iconWrapper, icon, iconOpen, contentOpen, text, component } = styles;

  const iconClass = classnames(icon, {
    [`${iconOpen}`]: isExpanded
  });
  const contentClass = classnames(content, {
    [`${contentOpen}`]: isExpanded
  });

  return (
    <div className={wrapper}>
      <div className={header} onClick={() => setIsExpanded(!isExpanded)} aria-hidden="true">
        <div className={text}>{headerText}</div>
        {headerComponent && <div className={component}>{headerComponent}</div>}
        <div className={iconWrapper}>
          <SVGIcon glyph={GLYPHS.CHEVRON_DOWN} className={iconClass} />
        </div>
      </div>
      <div className={contentClass}>{isExpanded && children}</div>
    </div>
  );
};

SmartAccordion.propTypes = {
  headerText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  headerComponent: PropTypes.element,
  children: PropTypes.element.isRequired
};

export default SmartAccordion;
