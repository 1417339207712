import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SmartTableHeaderCell from '../SmartTableHeaderCell/SmartTableHeaderCell';
import styles from './SmartTableHeaderSortableCell.scss';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';

const SmartTableHeaderSortableCell = ({
  sortElement,
  sortDir,
  children,
  colSpan,
  align,
  order,
  handleSortClick,
  colKey,
  borderType,
  tagName,
  hasPadding,
  isRotatedHeader,
  orderIcon
}) => {
  const {
    smartTableHeaderSortableCellWrapper,
    smartTableHeaderSortableCellIcons,
    smartTableHeaderSortableCellIconsWrapper,
    smartTableHeaderSortableCellTextLeft,
    smartTableHeaderSortableCellTextCenter,
    smartTableHeaderSortableCellTextRight,
    smartTableHeaderSortableCellFirst,
    smartTableHeaderSortableCellLast,
    smartTableHeaderSortableCellDashed,
    smartTableHeaderSortableCellLastDashed,
    smartTableHeaderSortableCellSort
  } = styles;

  const sortIconClass = classnames(smartTableHeaderSortableCellSort, {
    [`${smartTableHeaderSortableCellTextLeft}`]: align === 'left',
    [`${smartTableHeaderSortableCellTextCenter}`]: align === 'center',
    [`${smartTableHeaderSortableCellTextRight}`]: align === 'right',
    [`${smartTableHeaderSortableCellFirst}`]: orderIcon === 'first',
    [`${smartTableHeaderSortableCellLast}`]: orderIcon === 'last',
    [`${smartTableHeaderSortableCellDashed}`]: borderType === 'dashed' && orderIcon !== 'first',
    [`${smartTableHeaderSortableCellLastDashed}`]: borderType === 'dashed' && orderIcon === 'last'
  });

  const renderSortArrows = () => {
    if (sortElement !== colKey || sortDir === 'none') {
      return (
        <div className={smartTableHeaderSortableCellIconsWrapper}>
          <SVGIcon className={smartTableHeaderSortableCellIcons} glyph={GLYPHS.ICON_SORT_OPTIONS} />
        </div>
      );
    }

    return (
      <div className={smartTableHeaderSortableCellIconsWrapper}>
        <SVGIcon
          className={smartTableHeaderSortableCellIcons}
          glyph={sortDir === 'asc' ? GLYPHS.ICON_SORT_ASCENDING : GLYPHS.ICON_SORT_DESCENDING}
        />
      </div>
    );
  };

  const handleSortAction = () => {
    handleSortClick(colKey);
  };

  const renderInnerContent = () => {
    if (isRotatedHeader) {
      return (
        <>
          <tr>
            <td>{children}</td>
          </tr>
          <tr>
            <td className={sortIconClass}>
              <div className={smartTableHeaderSortableCellWrapper}>{renderSortArrows()}</div>
            </td>
          </tr>
        </>
      );
    }

    return (
      <>
        {children}
        <div className={smartTableHeaderSortableCellWrapper}>{renderSortArrows()}</div>
      </>
    );
  };

  return (
    <SmartTableHeaderCell
      tagName={tagName}
      align={align}
      colSpan={colSpan}
      order={order}
      handleClick={handleSortAction}
      borderType={borderType}
      hasPadding={hasPadding}
    >
      <>{renderInnerContent()}</>
    </SmartTableHeaderCell>
  );
};

SmartTableHeaderSortableCell.defaultProps = {
  tagName: 'th',
  borderType: 'normal',
  hasPadding: true,
  isRotatedHeader: false
};

SmartTableHeaderSortableCell.propTypes = {
  colKey: PropTypes.string.isRequired,
  sortElement: PropTypes.string,
  sortDir: PropTypes.oneOf(['asc', 'desc', 'none']),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.arrayOf(PropTypes.element)]),
  colSpan: PropTypes.number,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  order: PropTypes.oneOf(['first', 'between', 'last']),
  orderIcon: PropTypes.oneOf(['first', 'between', 'last']),
  borderType: PropTypes.oneOf(['dashed', 'normal']),
  handleSortClick: PropTypes.func,
  tagName: PropTypes.oneOf(['div', 'th']),
  hasPadding: PropTypes.bool,
  isRotatedHeader: PropTypes.bool
};

export default SmartTableHeaderSortableCell;
