import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartTableCellBand.scss';
import SmartTableCell from '../SmartTableCell/SmartTableCell';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';

const SmartTableCellBand = ({ children, colSpan, align, order, borderType, dataType }) => {
  const { smartTableCellBandWrapper, smartTableCellBand, smartTableCellBandText, smartTableCellBandTextNA } = styles;

  const isGoodDataType = dataType === 'developing' || dataType === 'secure' || dataType === 'extending';

  const renderCellBand = () => {
    if (dataType) {
      switch (dataType) {
        case 'developing':
          return <SVGIcon className={smartTableCellBand} glyph={GLYPHS.ICON_DSE_DEVELOPING} />;
        case 'secure':
          return <SVGIcon className={smartTableCellBand} glyph={GLYPHS.ICON_DSE_SECURE} />;
        case 'extending':
          return <SVGIcon className={smartTableCellBand} glyph={GLYPHS.ICON_DSE_EXTENDING} />;
        default:
          return <></>;
      }
    }

    return <div className={smartTableCellBand}>-</div>;
  };

  return (
    <SmartTableCell colSpan={colSpan} align={align} order={order} borderType={borderType}>
      {isGoodDataType && (
        <>
          <div className={smartTableCellBandWrapper}>{renderCellBand()}</div>
          <div className={smartTableCellBandText}>{children}</div>
        </>
      )}
      {!isGoodDataType && <div className={smartTableCellBandTextNA}>n/a</div>}
    </SmartTableCell>
  );
};

SmartTableCellBand.defaultProps = {
  align: 'left',
  order: 'between',
  borderType: 'normal'
};

SmartTableCellBand.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  colSpan: PropTypes.number,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  order: PropTypes.oneOf(['first', 'between', 'last']),
  borderType: PropTypes.oneOf(['dashed', 'normal']),
  dataType: PropTypes.oneOf(['developing', 'secure', 'extending', 'awaiting', 'n/a']).isRequired
};

export default SmartTableCellBand;
