/**
 * IMPORTANT - Smart content is served from iframes in kerboodle
 * ALSO IMPORTANT - Current implementation is a Proof Of Concept
 *                  Some of the logic can change when propper API calls are implemented
 *
 * This components acts like the controller for everything related to teacher reporting
 * There are two levels of tabs that work off each other
 *    - top level tabs - All Classes, Group and Student
 *    - second level tabs - Curriculum, Engagement and Tasks
 *
 * When the iframe is "called" the relevant tabs are highlighted based on the url ( see parsePathnames)
 *
 * Switching between tabs is done in two different ways
 *    - by clicking the tab       - router.push is called with the url being a combination of the
 *                                  selected top and second level tabs and the entity id
 *                                - this is done internally by the component
 *    - by clickling on a <Link>  - done from any sub-pages rendered
 */

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { SVGIcon } from '../../components/';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import SecondLevelTabsWrapper from './../../components/SecondLevelTabs/SecondLevelTabsWrapper';

const tabs = [
  {
    label: 'Tasks',
    key: 'tasks'
  },
  {
    label: 'Course',
    key: 'courses'
  }
];

class SmartTeacherLayout extends React.Component {
  constructor(props) {
    super(props);
    const urlPayload = this.parsePathname(location.pathname);
    const initialState = {
      tabs,
      activeTabId: '',
      studentId: 0
    };
    this.state = this.getStateFromUrlData(initialState, urlPayload);
  }

  componentDidUpdate(prevProps) {
    // this handles tab "selection" from <Link> navigation
    if (this.props.location !== prevProps.location) this.onLocationChanged();
  }

  onLocationChanged = () => {
    // handles tab highlighting when navigation is done via an <Link>
    this.setState(state => this.getStateFromUrlData(state, this.parsePathname(location.pathname)));
  };

  onTabsClick = tabId => {
    this.setState(state => ({ ...state, activeTabId: tabId }), this.redirectToTabGeneratedUrl);
  };

  getStateFromUrlData = (prevState, urlPayload) => ({
    ...prevState,
    activeTabId: urlPayload.activeTabId,
    studentId: urlPayload.studentId
  });

  parsePathname = (pathname = '') => {
    const regex = /^\/dev\/student-reporting\/(?<activeTabId>[^/]+)\/(?<studentId>[^/]+)$/;

    if (!regex.test(pathname)) {
      throw Error(`Pathname ${pathname} not supported`);
    }

    return pathname.match(regex).groups;
  };

  redirectToTabGeneratedUrl = () => {
    if (this.state.activeTabId === '') {
      return;
    }

    const url = `/dev/student-reporting/${this.state.activeTabId}/${this.state.studentId}`;

    if (url !== location.pathname) {
      this.props.router.push(url);
    }
  };

  render() {
    return (
      <div>
        <SecondLevelTabsWrapper
          items={this.state.tabs}
          activeItemKey={this.state.activeTabId}
          handleClickItem={this.onTabsClick}
          infoButton={<SVGIcon glyph={GLYPHS.ICON_INFORMATION_BUBBLE} />}
        />
        {this.props.children}
      </div>
    );
  }
}

SmartTeacherLayout.propTypes = {
  children: PropTypes.any,
  router: PropTypes.any,
  location: PropTypes.object.isRequired
};

export default withRouter(SmartTeacherLayout);
