import React from 'react';
import PropTypes from 'prop-types';

const SmartTableHeader = ({ children }) => <thead>{children}</thead>;

SmartTableHeader.defaultProps = {
  children: {}
};

SmartTableHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default SmartTableHeader;
