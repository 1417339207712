import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SmartTable from '../../../SmartTable/SmartTable';
import SmartTableHeader from '../../../SmartTable/SmartTableHeader/SmartTableHeader';
import SmartTableHeaderRow from '../../../SmartTable/SmartTableHeaderRow/SmartTableHeaderRow';
import SmartTableBody from '../../../SmartTable/SmartTableBody/SmartTableBody';
import SmartTableRow from '../../../SmartTable/SmartTableRow/SmartTableRow';
import SmartTableCell from '../../../SmartTable/SmartTableCell/SmartTableCell';
import SmartTableHeaderSortableCell from '../../../SmartTable/SmartTableHeaderSortableCell/SmartTableHeaderSortableCell';
import SmartTableCellBand from '../../../SmartTable/SmartTableCellBand/SmartTableCellBand';

const SmartStudentsReportTasksTable = ({ items, handleSortAction, sortDir, sortElement, handleItemClick }) => {
  if (items.length === 0) {
    return <div>No data items to be displayed...</div>;
  }

  const renderColumns = () => (
    <>
      <SmartTableHeaderSortableCell
        order="first"
        borderType="normal"
        colKey="task"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        Task
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="dse_band"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        DSE Band
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="mark"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        Mark
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="between"
        borderType="dashed"
        colKey="mark_percentage"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        %
      </SmartTableHeaderSortableCell>
      {/* <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="effort_points"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        Effort points
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="accuracy_points"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        Accuracy points
      </SmartTableHeaderSortableCell> */}
      <SmartTableHeaderSortableCell
        order="between"
        borderType="normal"
        colKey="date_completed"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        <>Date completed</>
      </SmartTableHeaderSortableCell>
      <SmartTableHeaderSortableCell
        order="last"
        borderType="normal"
        colKey="content_type"
        handleSortClick={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
      >
        Content type
      </SmartTableHeaderSortableCell>
    </>
  );

  const prettifyDate = date => moment.unix(date).format('DD/M/YY');

  const renderMarkPercentageCell = (data, type) => {
    if (data) {
      if (data === 'awaiting') {
        return <span style={{ fontStyle: 'italic' }}>Awaiting</span>;
      } else if (data === 'n/a') {
        return <span>n/a</span>;
      }

      if (type === 'mark') {
        return <>{data}</>;
      }

      return (
        <>
          {data}
          <sup>%</sup>
        </>
      );
    }
    return <span style={{ fontStyle: 'italic' }}>Awaiting</span>;
  };

  const renderRows = () =>
    items.map((item, index) => (
      <SmartTableRow key={item.id} type={index % 2 !== 0 ? 'even' : 'odd'}>
        <SmartTableCell order="first" width="400px">
          <a onClick={() => handleItemClick(item)} aria-hidden="true">
            {item.task}
          </a>
        </SmartTableCell>
        <SmartTableCellBand dataType={item.dseLabel}>{item.dseLabel}</SmartTableCellBand>
        <SmartTableCell>{renderMarkPercentageCell(item.markLabel, 'mark')}</SmartTableCell>
        <SmartTableCell borderType="dashed">
          {renderMarkPercentageCell(item.percentageLabel, 'percentage')}
        </SmartTableCell>
        {/* <SmartTableCell>{item.effortPoints}</SmartTableCell>
        <SmartTableCell>{item.accuracyPoints}</SmartTableCell> */}
        <SmartTableCell>{prettifyDate(item.dateCompleted)}</SmartTableCell>
        <SmartTableCell order="last">{item.contentType}</SmartTableCell>
      </SmartTableRow>
    ));

  return (
    <>
      <SmartTable>
        <SmartTableHeader>
          <SmartTableHeaderRow>{renderColumns()}</SmartTableHeaderRow>
        </SmartTableHeader>
        <SmartTableBody>{renderRows()}</SmartTableBody>
      </SmartTable>
    </>
  );
};

SmartStudentsReportTasksTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleSortAction: PropTypes.func.isRequired,
  sortElement: PropTypes.string,
  sortDir: PropTypes.oneOf(['asc', 'desc', 'none']),
  handleItemClick: PropTypes.func
};

export default SmartStudentsReportTasksTable;
