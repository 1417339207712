import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../Link/Link';

const SmartOverlayPanelTableLink = ({ link, displayText }) => {
  // REGEX to test if it's a file (doc/pdf)
  const regex = /^.*\.(doc|DOC|pdf|PDF|docx|DOCX|txt|TXT)$/;

  const checkLink = () => {
    if (regex.test(link)) {
      return (
        <a href={link} target="_blank" aria-hidden="true" rel="noreferrer">
          {displayText}
        </a>
      );
    }
    return <Link to="/dev/demo-quad">{displayText}</Link>;
  };

  return <>{checkLink()}</>;
};

SmartOverlayPanelTableLink.propTypes = {
  link: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired
};

export default SmartOverlayPanelTableLink;
