import React from 'react';
import PropTypes from 'prop-types';

const SmartTeacherReportingStudentTasks = ({ params }) => <div>Tasks for student ( {params.studentId})</div>;

SmartTeacherReportingStudentTasks.propTypes = {
  params: PropTypes.shape({ studentId: PropTypes.string.isRequired })
};

export default SmartTeacherReportingStudentTasks;
