import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useMediaQuery from '../../../../utils/mediaQuery';
import TableAreaMobile from './TableAreaMobile';
import TableAreaDesktop from './TableAreaDesktop';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import { RecordsContext, SortingContext, FiltersContext } from '../ContextProviders';
import styles from './TableArea.scss';

const ROWS_PER_PAGE = 10;
const FILTER_ALL_OPTION_VALUE = 'All';

function TableArea({ isFixedWidth, handleBackToTopClick }) {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [records, setRecords] = useState([]);
  const [numberOfFilteredRecords, setNumberOfFilteredRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const sortContext = useContext(SortingContext);
  const recordsContext = useContext(RecordsContext);
  const filtersContext = useContext(FiltersContext);

  // #region Sorting
  const SORT_DATA_HANDLERS = {
    task: (a, b) => (a.task > b.task && 1) || -1,
    dse_band: (a, b) => (a.dseValue > b.dseValue && 1) || -1,
    mark: (a, b) => (a.percentage > b.percentage && 1) || -1,
    mark_percentage: (a, b) => (a.percentage > b.percentage && 1) || -1,
    effort_points: (a, b) => (a.effortPoints > b.effortPoints && 1) || -1,
    accuracy_points: (a, b) => (a.accuracyPoints > b.accuracyPoints && 1) || -1,
    content_type: (a, b) => (a.contentType > b.contentType && 1) || -1,
    date_completed: (a, b) => (a.dateCompleted > b.dateCompleted && 1) || -1
  };

  const sortData = data => {
    const sortHandler = SORT_DATA_HANDLERS[sortContext.column];
    if (sortHandler === undefined) return data;

    const sortOrderModifier = sortContext.direction === 'asc' ? 1 : -1;
    return data.sort((a, b) => sortOrderModifier * sortHandler(a, b, sortOrderModifier));
  };
  // #endregion

  const filterData = data => {
    let filteredData = [...data];
    if (filtersContext.values.taskType !== FILTER_ALL_OPTION_VALUE) {
      filteredData = filteredData.filter(item => item.contentType === filtersContext.values.taskType);
    }
    if (filtersContext.values.subject !== FILTER_ALL_OPTION_VALUE) {
      filteredData = filteredData.filter(item => item.subject === filtersContext.values.subject);
    }
    if (filtersContext.values.assignedBy !== FILTER_ALL_OPTION_VALUE) {
      filteredData = filteredData.filter(item => item.assignedBy === filtersContext.values.assignedBy);
    }

    return filteredData;
  };
  const paginateData = (data = []) => data.slice(ROWS_PER_PAGE * (currentPage - 1), ROWS_PER_PAGE * currentPage);

  useEffect(() => {
    const filteredItems = filterData(recordsContext.records);
    const sortedItems = sortData(filteredItems);
    const paginatedItems = paginateData(sortedItems);
    setRecords(paginatedItems);
    setNumberOfFilteredRecords(filteredItems.length);
  }, [
    filtersContext.values.assignedBy,
    filtersContext.values.subject,
    filtersContext.values.taskType,
    sortContext.column,
    sortContext.direction,
    currentPage
  ]);

  const renderDesktop = () => <TableAreaDesktop items={records} />;
  const renderMobile = () => <TableAreaMobile items={records} />;

  const renderPagination = () => (
    <TablePagination
      onPageChangedHandler={page => setCurrentPage(page)}
      totalNumberOfEntries={numberOfFilteredRecords}
      page={currentPage}
      rowsPerPage={numberOfFilteredRecords}
      onClickBackToTopHandler={handleBackToTopClick}
    />
  );

  const renderTable = () => (isMobile ? renderMobile() : renderDesktop());

  return (
    <div className={styles.tableWrapper}>
      <div
        className={classnames({
          [`${styles.tableContainerFixedWidth}`]: isFixedWidth,
          [`${styles.tableContainerFullWidth}`]: !isFixedWidth
        })}
      >
        {renderTable()}
        {numberOfFilteredRecords !== 0 && renderPagination()}
      </div>
    </div>
  );
}

TableArea.propTypes = {
  handleBackToTopClick: PropTypes.func.isRequired,
  isFixedWidth: PropTypes.bool
};
export default TableArea;
