import React, { useState, useEffect, useRef } from 'react';
import FiltersArea from './FiltersArea';
import StatisticsArea from './StatisticsArea';
import TableArea from './TableArea';
import TaskInfoArea from './TaskInfoArea';
import SmartLoadingSpinner from '../../../components/SmartLoadingSpinner/SmartLoadingSpinner';
import {
  SortingContextProvider,
  RecordsContextProvider,
  FiltersContextProvider,
  TaskInfoSidebarContextProvider
} from './ContextProviders';

function StudentReportingTasks() {
  const [records, setRecords] = useState([]);
  const [recordsAreLoading, setRecordsAreLoading] = useState(false);

  const pageRef = useRef();

  const onClickBackToTopHandler = () => {
    const currentRef = pageRef.current || null;
    console.log('BACK TO TOP', currentRef);

    if (currentRef !== null) {
      console.log('BACK TO TOP 111');
      currentRef.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderAreas = () => (
    <>
      <StatisticsArea />
      <FiltersArea />
      <TableArea handleBackToTopClick={onClickBackToTopHandler} />
      <TaskInfoArea />
    </>
  );
  const fetchData = async () => {
    setRecordsAreLoading(true);
    // @ts-ignore
    const response = await (await fetch(`${__API_BASE__}/open/smart/student-reporting/1/tasks`)).json();
    setRecords(response.data);
    setRecordsAreLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderLoading = () => <SmartLoadingSpinner />;

  return (
    <div ref={pageRef}>
      {recordsAreLoading ? (
        renderLoading()
      ) : (
        <RecordsContextProvider items={records}>
          <FiltersContextProvider items={records}>
            <TaskInfoSidebarContextProvider>
              <SortingContextProvider>{renderAreas()}</SortingContextProvider>
            </TaskInfoSidebarContextProvider>
          </FiltersContextProvider>
        </RecordsContextProvider>
      )}
    </div>
  );
}

export default StudentReportingTasks;
