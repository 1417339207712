import React from 'react';
import PropTypes from 'prop-types';

const SmartTableHeaderRow = ({ children }) => <tr>{children}</tr>;

SmartTableHeaderRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default SmartTableHeaderRow;
