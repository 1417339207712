import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import SmartStudentsReportTasksTable from './Desktop/SmartStudentsReportTasksTable';
import SmartStudentsReportTasksTableMobile from './Mobile/SmartStudentsReportTasksTableMobile';
import useMediaQuery from '../../../utils/mediaQuery';
import TablePagination from '../../TablePagination/TablePagination';
import classnames from 'classnames';
import styles from './SmartStudentsReportTasksTableContainer.scss';
import SmartOverlayPanelTableContainer from '../SmartOverlayPanelTable/SmartOverlayPanelTableContainer';
import { sortAndFilterOverlayContext } from '../SmartStudentReportingTasks';

const SmartStudentsReportTasksTableContainer = ({ items, filters, handleBackToTopClick, isFixedWidth }) => {
  const [sortElement, setSortElement] = useState('date_completed');
  const [sortDir, setSortDir] = useState('desc');
  const [mobileSortDir, setMobileSortDir] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [records, setRecords] = useState(items);
  const [numberOfFilteredRecords, setNumberOfFilteredRecords] = useState(0);
  const ROWS_PER_PAGE = 10;
  const FILTER_ALL_OPTION_VALUE = 'All';

  // For Overlay Panel
  const sortAndFilterContext = useContext(sortAndFilterOverlayContext);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  const [overlayType, setOverlayType] = useState('assessment');

  const isMobile = useMediaQuery('(max-width: 1023px)');

  const sortAndFilterData = {
    filterValues: {
      sortBy: sortAndFilterContext.filters.sortBy,
      contentType: sortAndFilterContext.filters.contentType,
      assignedBy: sortAndFilterContext.filters.assignedBy,
      subject: sortAndFilterContext.filters.subject
    },
    selectedFilterValues: {
      selectedSortBy: sortAndFilterContext.selectedFilters.selectedSortBy,
      selectedContentType: filters.contentType,
      selectedAssignedBy: filters.assignedBy,
      selectedSubject: filters.subject
    },
    sortDir: mobileSortDir,
    displayPinkDot: sortAndFilterContext.displayPinkDot,
    handleSetSortDir: setMobileSortDir,
    handleFilterValueChange: sortAndFilterContext.handleChange,
    handleSortValueChange: sortAndFilterContext.handleChange,
    handleClearFilters: sortAndFilterContext.handleClearFilters
  };

  // #region Sorting
  const SORT_DATA_HANDLERS = {
    task: (a, b) => (a.task > b.task && 1) || -1,
    dse_band: (a, b) => (a.dseValue > b.dseValue && 1) || -1,
    mark: (a, b) => (a.percentage > b.percentage && 1) || -1,
    mark_percentage: (a, b) => (a.percentage > b.percentage && 1) || -1,
    effort_points: (a, b) => (a.effortPoints > b.effortPoints && 1) || -1,
    accuracy_points: (a, b) => (a.accuracyPoints > b.accuracyPoints && 1) || -1,
    content_type: (a, b) => (a.contentType > b.contentType && 1) || -1,
    date_completed: (a, b) => (a.dateCompleted > b.dateCompleted && 1) || -1
  };

  const sortData = data => {
    const sortHandler =
      SORT_DATA_HANDLERS[isMobile ? sortAndFilterData.selectedFilterValues.selectedSortBy.id : sortElement];
    if (sortHandler === undefined) return data;

    const desktopOrdering = sortDir === 'asc' ? 1 : -1;
    const mobileOrdering = mobileSortDir === 'asc' ? 1 : -1;

    const sortOrderModifier = isMobile ? mobileOrdering : desktopOrdering;
    return data.sort((a, b) => sortOrderModifier * sortHandler(a, b, sortOrderModifier));
  };
  // #endregion

  const filterData = data => {
    let filteredData = [...data];
    const filteredContentType = filters.contentType.label ? filters.contentType.label : filters.contentType;
    const filteredSubject = filters.subject.label ? filters.subject.label : filters.subject;
    const filteredAssignedBy = filters.assignedBy.label ? filters.assignedBy.label : filters.assignedBy;
    if (filteredContentType !== FILTER_ALL_OPTION_VALUE) {
      filteredData = filteredData.filter(item => item.contentType === filteredContentType);
    }
    if (filteredSubject !== FILTER_ALL_OPTION_VALUE) {
      filteredData = filteredData.filter(item => item.subject === filteredSubject);
    }
    if (filteredAssignedBy !== FILTER_ALL_OPTION_VALUE) {
      filteredData = filteredData.filter(item => item.assignedBy === filteredAssignedBy);
    }

    return filteredData;
  };

  const paginateData = (data = []) => data.slice(ROWS_PER_PAGE * (currentPage - 1), ROWS_PER_PAGE * currentPage);

  useEffect(() => {
    const filteredItems = filterData(items);
    const sortedItems = sortData(filteredItems);
    const paginatedData = paginateData(sortedItems);
    setNumberOfFilteredRecords(paginatedData.length);
    setRecords(paginatedData);
  }, [sortElement, sortDir, filters, currentPage]);

  const handleSortAction = key => {
    if (key !== sortElement) {
      setSortElement(key);
      setSortDir('asc');
      return;
    }

    setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
  };

  const handlePageChangedAction = page => {
    setCurrentPage(page);
  };

  /**
   * This will be replace later on when there is real data coming from the API
   */
  const getItemSubContentType = task => {
    switch (task.subContentType) {
      case 'n/a':
        return 'nonMarked';
      case 'auto':
        return 'autoMarked';
      case 'manually':
        return 'manualMarked';
      default:
        return 'autoMarked';
    }
  };

  const getItemContentType = task => {
    switch (task.contentType) {
      case 'Next steps':
        return 'levelup';
      case 'Assignment':
        return 'assessment';
      case 'Reactivate':
        return 'reactivate';
      case 'Other':
        return getItemSubContentType(task);
      default:
        return 'assessment';
    }
  };

  const handleItemClick = task => {
    // TODO: check a better solution
    setOverlayType(getItemContentType(task));
    setIsOverlayOpen(!isOverlayOpen);
    setSelectedTask(task);
  };

  const handleOverlayPanelClose = () => {
    setIsOverlayOpen(!isOverlayOpen);
    setSelectedTask(null);
  };

  const onClickBackToTopHandler = () => {
    handleBackToTopClick();
  };

  const renderTable = () =>
    isMobile ? (
      <SmartStudentsReportTasksTableMobile items={records} handleItemClick={handleItemClick} />
    ) : (
      <SmartStudentsReportTasksTable
        items={records}
        handleSortAction={handleSortAction}
        sortDir={sortDir}
        sortElement={sortElement}
        handleItemClick={handleItemClick}
      />
    );

  const renderPagination = () => (
    <TablePagination
      onPageChangedHandler={handlePageChangedAction}
      totalNumberOfEntries={filterData(items).length}
      page={currentPage}
      rowsPerPage={numberOfFilteredRecords}
      onClickBackToTopHandler={onClickBackToTopHandler}
    />
  );

  return (
    <div className={styles.tableWrapper}>
      <div
        className={classnames({
          [`${styles.tableContainerFixedWidth}`]: isFixedWidth,
          [`${styles.tableContainerFullWidth}`]: !isFixedWidth
        })}
      >
        {renderTable()}
        {records.length !== 0 && renderPagination()}
      </div>
      <SmartOverlayPanelTableContainer
        isOpen={isOverlayOpen}
        handleClose={handleOverlayPanelClose}
        item={selectedTask}
        type={overlayType}
      />
      <SmartOverlayPanelTableContainer
        isOpen={sortAndFilterContext.isOverlayOpen}
        handleClose={sortAndFilterContext.handleOverlayPanelClose}
        item={sortAndFilterData}
        type="sortAndFilter"
      />
    </div>
  );
};

SmartStudentsReportTasksTableContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleBackToTopClick: PropTypes.func.isRequired,
  isFixedWidth: PropTypes.bool,
  filters: PropTypes.shape({
    contentType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string
      })
    ]),
    subject: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string
      })
    ]),
    assignedBy: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string
      })
    ])
  })
};

SmartStudentsReportTasksTableContainer.defaultProps = {
  isFixedWidth: true
};

export default SmartStudentsReportTasksTableContainer;
