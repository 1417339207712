const availableFilters = [
  {
    label: 'Task type',
    id: 'taskType'
  },
  {
    label: 'Subject',
    id: 'subject'
  },
  {
    label: 'Assigned by',
    id: 'assignedBy'
  }
];

export default availableFilters;
