import React, { useContext } from 'react';
import styles from './StatisticsArea.scss';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import { RecordsContext } from '../ContextProviders';
import StatisticsAreaMobile from './StatisticsAreaMobile';
import StatisticsAreaDesktop from './StatisticsAreaDesktop';
import useMediaQuery from '../../../../utils/mediaQuery';

const StatisticsArea = () => {
  const { iconStyle } = styles;
  const isMobile = useMediaQuery('(max-width: 1023px)'); // maybe this can be moved to context
  const recordsContext = useContext(RecordsContext);
  const records = recordsContext.records;

  // values are not final
  const assignmentsCompleted = records.length;
  const levelUpsCompleted = records.filter(record => record.contentType === 'Next Steps').length;
  const reactivatesCompleted = records.filter(record => record.contentType === 'Assignment').length;

  const fields = [
    {
      icon: <SVGIcon glyph={GLYPHS.ICON_ASSESSMENTS} className={iconStyle} />,
      number: assignmentsCompleted,
      title: 'Assignments Complete'
    },
    {
      icon: <SVGIcon glyph={GLYPHS.ICON_LEVEL_UP} className={iconStyle} />,
      number: levelUpsCompleted,
      title: 'Level Ups Completed'
    },
    {
      icon: <SVGIcon glyph={GLYPHS.ICON_MEMORY_STRENGTH} className={iconStyle} />,
      number: reactivatesCompleted,
      title: 'Reactivates Completed'
    }
  ];

  const renderDesktop = () => <StatisticsAreaDesktop items={fields} />;
  const renderMobile = () => <StatisticsAreaMobile items={fields} />;

  return isMobile ? renderMobile() : renderDesktop();
};
export default StatisticsArea;
