import React from 'react';
import PropTypes from 'prop-types';

const SmartTeacherReportingStudentEngagement = ({ params }) => <div>Engagement for student ( {params.studentId})</div>;

SmartTeacherReportingStudentEngagement.propTypes = {
  params: PropTypes.shape({ studentId: PropTypes.string.isRequired })
};

export default SmartTeacherReportingStudentEngagement;
