import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SmartTable.scss';

const SmartTable = ({ children, hasFrozenFirstColumn }) => {
  const { smartTable, smartTableFrozen } = styles;
  const [isScrolling, setIsScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const tableRef = useRef();

  const handleStopScrollOrDrag = () => {
    setIsScrolling(false);
  };
  const handleStartScrollOrDrag = event => {
    setIsScrolling(true);
    setClientX(event.clientX);
  };
  const handleMovingScrollOrDrag = event => {
    const currentTableRef = tableRef.current || null;
    if (currentTableRef !== null && isScrolling) {
      currentTableRef.scrollLeft = scrollX - event.clientX + clientX;
      setScrollX(currentTableRef.scrollLeft);
      setClientX(event.clientX);
    }
  };

  if (hasFrozenFirstColumn) {
    return (
      <div
        aria-hidden="true"
        className={smartTableFrozen}
        ref={tableRef}
        onMouseDown={handleStartScrollOrDrag}
        onMouseUp={handleStopScrollOrDrag}
        onMouseMove={handleMovingScrollOrDrag}
        onTouchStart={handleStartScrollOrDrag}
        onTouchEnd={handleStopScrollOrDrag}
        onTouchMove={handleMovingScrollOrDrag}
      >
        <table className={smartTable}>{children}</table>
      </div>
    );
  }

  if (children) {
    return <table className={smartTable}>{children}</table>;
  }

  return <></>;
};

SmartTable.defaultProps = {
  hasFrozenFirstColumn: false
};

SmartTable.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  hasFrozenFirstColumn: PropTypes.bool
};

export default SmartTable;
