import React from 'react';
import { Helmet } from 'react-helmet';

import TopRow from '../../../../routes/Homepage/parts/TopRow/TopRow';

const SmartWelcomePage = () => (
  <div>
    <Helmet title="Welcome to Smart" />
    <TopRow />
  </div>
);

export default SmartWelcomePage;
