import React from 'react';
import styles from './SmartStudentReportingCoursesStatisticsArea.scss';
import PropTypes from 'prop-types';
import useMediaQuery from '../../../utils/mediaQuery';
import SmartList from '../../SmartList/SmartList';
import SmartListItem from '../../SmartList/SmartListItem/SmartListItem';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import SmartBreadcrumbFromTree from '../SmartBreadcrumbFromTree/SmartBreadcrumbFromTree';
import StackedBarChart from '../../StackedBar/StackedBarChart';
import StatisticalLozenges from '../../StatisticalLozenges/StatisticalLozenges';
import SmartProgressBar from '../../SmartProgressBar/SmartProgressBar';

const SmartStudentReportingCoursesStatisticsArea = ({
  tree,
  activeItemId,
  setActiveItemId,
  dseLevels,
  questionsAnswered,
  memoryStrength
}) => {
  const {
    statisticsArea,
    breadcrumb,
    lozengesWrapper,
    statisticsItem,
    iconNormal,
    memoryStrengthComponent,
    memoryStrengthIcon
  } = styles;

  let icon = <SVGIcon glyph={GLYPHS.ICON_MEMORY_STRENGTH} className={iconNormal} />;

  if (memoryStrength === 0) {
    icon = <SVGIcon glyph={GLYPHS.ICON_MEMORY_STRENGTH} className={iconNormal} />;
  } else if (memoryStrength > 0 && memoryStrength < 100) {
    icon = <SVGIcon glyph={GLYPHS.ICON_MEMORY_STRENGTH_PARTIAL} className={iconNormal} />;
  } else if (memoryStrength === 100) {
    icon = <SVGIcon glyph={GLYPHS.ICON_MEMORY_STRENGTH_MOBILE_FULL} className={iconNormal} />;
  }

  const items = [
    {
      icon: <SVGIcon glyph={GLYPHS.ICON_QUESTIONS_ANSWERED} className={iconNormal} />,
      number: questionsAnswered,
      title: 'Questions answered'
    },
    {
      icon,
      number: (
        <p>
          {memoryStrength}
          <sup>%</sup>
        </p>
      ),
      title: 'Memory strength'
    }
  ];

  const renderForDesktop = () => (
    <div className={statisticsArea}>
      <div className={breadcrumb}>
        <SmartBreadcrumbFromTree tree={tree} activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
      </div>
      <div className={lozengesWrapper}>
        <div className={statisticsItem}>
          <StackedBarChart items={dseLevels} />
          <span>DSE band</span>
        </div>
        <div className={statisticsItem}>
          <StatisticalLozenges
            text={`${questionsAnswered}`}
            icon={<SVGIcon className={iconNormal} glyph={GLYPHS.ICON_QUESTIONS_ANSWERED} />}
          />
          <span>Questions answered</span>
        </div>
        <div className={statisticsItem}>
          <StatisticalLozenges
            icon={<SVGIcon className={memoryStrengthIcon} glyph={GLYPHS.ICON_MEMORY_STRENGTH} />}
            component={
              <div className={memoryStrengthComponent}>
                <div>
                  {memoryStrength}
                  <sup>%</sup>
                </div>
                <SmartProgressBar percentage={memoryStrength} />
              </div>
            }
          />
          <span>Memory strength</span>
        </div>
      </div>
    </div>
  );

  const renderForMobile = () => (
    <div className={statisticsArea}>
      <SmartBreadcrumbFromTree tree={tree} activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
      <StackedBarChart items={dseLevels} />
      <SmartList>
        <SmartListItem
          className="smartList"
          label={items[0].icon}
          value={items[0].number}
          extraValue={items[0].title}
        />
        <SmartListItem
          className="smartList"
          label={items[1].icon}
          value={items[1].number}
          extraValue={items[1].title}
        />
      </SmartList>
    </div>
  );

  const isMobile = useMediaQuery('(max-width: 1023px)');
  return isMobile ? renderForMobile() : renderForDesktop();
};

SmartStudentReportingCoursesStatisticsArea.propTypes = {
  tree: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setActiveItemId: PropTypes.func.isRequired,
  dseLevels: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionsAnswered: PropTypes.number.isRequired,
  memoryStrength: PropTypes.number.isRequired
};

export default SmartStudentReportingCoursesStatisticsArea;
