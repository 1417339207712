import React, { useContext } from 'react';
import SmartDropdown from '../../../../../components/SmartDropdown/SmartDropdown';
import { FiltersContext } from '../../ContextProviders';
import availableFilters from '../AvailableFilters';
import styles from './FiltersAreaDesktop.scss';

function FiltersAreaDesktop() {
  const filters = useContext(FiltersContext);
  const { values, options, setFilterValue } = filters;
  return (
    <div className={styles.filters}>
      {availableFilters.map(({ id, label }) => (
        <SmartDropdown
          key={id}
          filterText={label}
          options={options[id]}
          value={values[id]}
          handleSetValueAction={value => setFilterValue(id, value.label)}
        />
      ))}
    </div>
  );
}
export default FiltersAreaDesktop;
