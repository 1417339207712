import colors from '../../globals/colors';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ThemeProvider from '../../theme/ThemeProvider';
import env from '../../globals/envSettings';

import { isEmbeddedInIframe } from '../../utils/platform';
import ErrorPage from '../StatePages/ErrorPage/ErrorPage';
import LoadingPage from '../StatePages/LoadingPage/LoadingPage';
import NGSLayout from '../NGSLayout/NGSLayout';
import EmptyLayout from '../EmptyLayout/EmptyLayout';
import NGSLogo from '../NGSLayout/NGSLogo/NGSLogo';
import colorLightness from '../../utils/colorLightness';
import colorOpacity from '../../utils/colorOpacity';
import colorDarken from '../../utils/colorDarken';

function isEmbedded(currentRoute) {
  return currentRoute.embedded || isEmbeddedInIframe() || sessionStorage.getItem('embedded-by-url') === 'true';
}

function injectTypographyStyles(theme) {
  if (document.getElementById('font-styles')) return;

  const fontStyle = document.createElement('style');
  fontStyle.id = 'font-styles';
  const { textColor } = theme.options;

  const fontCSSRule = `
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap');

body[data-theme="dark-theme"] {
  font-family: 'Source Sans Pro', sans-serif;
  color: ${textColor};
  fill: ${textColor};
}
`;

  fontStyle.appendChild(document.createTextNode(fontCSSRule));
  document.head.appendChild(fontStyle);
}

function applyTheme(theme) {
  const themeName = theme.name;

  if (themeName === 'dark') {
    injectTypographyStyles(theme);
  }

  document.body.setAttribute('data-theme', `${themeName}-theme`);

  document.body.style.setProperty('--ui-background', theme.options.uiBgColor);
  document.body.style.setProperty('--text-neutral', theme.options.textColor);
  document.body.style.setProperty('--text-neutral-darker', theme.options.textColorDarker);
  document.body.style.setProperty('--service-brand', theme.options.primaryColor);
  document.body.style.setProperty('--service-brand-darker', theme.options.darkerPrimaryColor);
  document.body.style.setProperty('--service-brand-darkest', theme.options.darkestPrimaryColor);
  document.body.style.setProperty('--service-top-bar-color', theme.options.topBarColor);
  document.body.style.setProperty('--accent-color', theme.options.accentColor);
  document.body.style.setProperty('--accent-color-lighter', colorOpacity(theme.options.accentColor, 0.2));
  document.body.style.setProperty('--accent-color-darker', colorDarken(theme.options.accentColor, 10));
  document.body.style.setProperty(
    '--product-contrast-color',
    colorLightness(theme.options.darkerPrimaryColor) === 1 ? '#000' : 'colors.COLOR_WHITE'
  );
}

function getLayoutForRoute(route) {
  const routesWithEmptyLayout = [':id/books/:bookId', ':id/resources/:resourceId'];
  return routesWithEmptyLayout.includes(route.path) ? EmptyLayout : NGSLayout;
}

class SmartRoot extends Component {
  static propTypes = {
    routes: PropTypes.array.isRequired,
    children: PropTypes.any,
    appError: PropTypes.bool.isRequired,
    appErrorReference: PropTypes.string,
    appReady: PropTypes.bool.isRequired,
    region: PropTypes.string,
    theme: PropTypes.shape({
      name: PropTypes.string.isRequired,
      options: PropTypes.object.isRequired
    })
  };

  static defaultProps = {
    embedded: false
  };

  componentWillMount() {
    const { region, theme } = this.props;

    if (region) document.body.setAttribute('data-region', region);
    if (theme && theme.name) applyTheme(theme);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.theme.name && nextProps.theme.name) {
      applyTheme(nextProps.theme);
    }
  }

  componentWillUnmount() {
    document.body.removeAttribute('data-theme');
    document.body.removeAttribute('data-region');
  }

  render() {
    const { routes, appError, appErrorReference, appReady, children, theme } = this.props;
    const currentRoute = routes[routes.length - 1];
    const embedded = isEmbedded(currentRoute);

    if (appError) {
      return <ErrorPage appErrorReference={appErrorReference} showHeader={!embedded} />;
    } else if (!appReady) {
      return <LoadingPage showHeader={false} customLogoComponent={<NGSLogo />} />;
    }

    const Layout = getLayoutForRoute(currentRoute);

    return (
      <ThemeProvider themeOptions={theme.options || {}}>
        <Layout embedded={embedded}>{children}</Layout>
      </ThemeProvider>
    );
  }
}

export default connect(state => {
  const region = localStorage.getItem('region');
  const {
    app: { appError, appErrorReference, appReady }
  } = state;

  const ngsEnv = env.ngs || { services: [] };
  const serviceConfig = ngsEnv.services.find(({ key }) => key === region);
  const theme = (serviceConfig && serviceConfig.theme) || {};

  return {
    appError,
    appErrorReference,
    appReady,
    region,
    theme
  };
})(SmartRoot);
