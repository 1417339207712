import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartOverlayPanelQuestion.scss';
import classnames from 'classnames';

const SmartOverlayPanelQuestion = ({ question, ...props }) => {
  const { link, label, value } = question;
  const { questionItem, questionItemLink, questionItemValue } = styles;

  return (
    <div className={classnames(questionItem, props.className)} {...props}>
      <a className={questionItemLink} href={link}>
        {label}
      </a>
      <span className={questionItemValue}>{value}</span>
    </div>
  );
};

SmartOverlayPanelQuestion.propTypes = {
  question: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  }),
  className: PropTypes.string
};

export default SmartOverlayPanelQuestion;
