import React from 'react';
import styles from './SmartLoadingSpinner.scss';

const SmartLoadingSpinner = () => (
  <div className={styles.wrapper}>
    <div className={styles.loadingSpinner} />
  </div>
);

export default SmartLoadingSpinner;
