// https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color

export default function colorLightness(color) {
  let hexColor = color;

  if (hexColor.indexOf('#') === 0) {
    hexColor = hexColor.slice(1);
  }

  if (hexColor.length === 3) {
    hexColor = hexColor[0] + hexColor[0] + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2];
  }

  if (hexColor.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 1 : 0;
}
