import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmartTableRow.scss';

const SmartTableRow = ({ children, type = 'even' }) => {
  const { smartTableRow, smartTableRowOdd } = styles;

  const rowClass = type === 'even' ? smartTableRow : smartTableRowOdd;

  return <tr className={rowClass}>{children}</tr>;
};

SmartTableRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  type: PropTypes.oneOf(['odd', 'even'])
};

export default SmartTableRow;
