import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import extractFilterOptionsFromDataset from '../../utils/filterOptions';

const FiltersContext = createContext({
  isSideBarVisible: false,
  isFilterActive: false,
  options: { taskType: [], subject: [], assignedBy: [] },
  values: { taskType: '', subject: '', assignedBy: '' },
  activeFilters: {},
  openSideBar: undefined,
  closeSideBar: undefined,
  updateOptions: undefined,
  setFilterValue: undefined,
  clearFilters: undefined
});

function FiltersContextProvider({ children, items }) {
  const [isSideBarVisible, setIsSideBarVisible] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});

  const [options, setOptions] = useState({
    taskType: extractFilterOptionsFromDataset(items, 'contentType'),
    subject: extractFilterOptionsFromDataset(items, 'subject'),
    assignedBy: extractFilterOptionsFromDataset(items, 'assignedBy')
  });

  const [values, setValues] = useState({ taskType: 'All', subject: 'All', assignedBy: 'All' });

  const closeSideBar = () => setIsSideBarVisible(false);
  const openSideBar = () => setIsSideBarVisible(true);
  const updateOptions = newOptions => setOptions(newOptions);
  const setFilterValue = (key, value) => setValues({ ...values, [key]: value });
  const clearFilters = () => setValues({ taskType: 'All', subject: 'All', assignedBy: 'All' });

  useEffect(() => {
    setIsFilterActive(false);
    const activeFiltersResult = {};
    if (values.taskType !== 'All' || values.subject !== 'All' || values.assignedBy !== 'All') {
      setIsFilterActive(true);

      Object.entries(values).forEach(([key, value]) => {
        if (value !== 'All') {
          activeFiltersResult[key === 'taskType' ? 'contentType' : key] = value;
        }
      });
    }
    setActiveFilters(activeFiltersResult);
  }, [values]);

  return (
    <FiltersContext.Provider
      value={{
        isSideBarVisible,
        isFilterActive,
        options,
        values,
        activeFilters,
        openSideBar,
        closeSideBar,
        updateOptions,
        setFilterValue,
        clearFilters
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

FiltersContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  items: PropTypes.array
};

export { FiltersContext, FiltersContextProvider };
