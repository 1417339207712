import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const RecordsContext = createContext({
  records: [],
  loadRecords: undefined
});

function RecordsContextProvider({ children, items }) {
  const [records, setRecords] = useState(items);

  const loadRecords = newRecords => setRecords(newRecords);

  // TODO: consider use case where records is updated from outside of the component via the props

  return (
    <RecordsContext.Provider
      value={{
        records,
        loadRecords
      }}
    >
      {children}
    </RecordsContext.Provider>
  );
}

RecordsContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  items: PropTypes.array
};

export { RecordsContext, RecordsContextProvider };
