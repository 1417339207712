import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';

import { loadCourse, unloadCourse } from '../../redux/actions/ngsCourse';
import colorLightness from '../../utils/colorLightness';
import withTheme from '../../theme/withTheme';

import ThemeProvider from '../../theme/ThemeProvider';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

export const productCSSVariables = ({
  textColor,
  productColor,
  productContrastColor
}) => ({
  '--product-color': productColor,
  '--product-contrast-color': productContrastColor || (productColor && colorLightness(productColor) === 1 ? '#000' : '#fff')
});

class NGSCourseLayout extends Component {
  static propTypes = {
    themeOptions: PropTypes.object,
    region: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    courseId: PropTypes.string.isRequired,
    courseTitle: PropTypes.string,
    courseTheme: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    children: PropTypes.any,

    loadCourse: PropTypes.func.isRequired,
    unloadCourse: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { orgId, region, courseId, loadCourse } = this.props;
    loadCourse(orgId, region, courseId);
  }

  componentWillUnmount() {
    this.props.unloadCourse();
  }

  render() {
    const { loading, themeOptions, courseTitle, courseTheme, children } = this.props;

    if (loading) {
      return <LoadingSpinner text="Loading course..." />;
    }

    let productContrast;

    if (courseTheme.productContrastColor) {
      productContrast = colorLightness(courseTheme.productContrastColor) === 1 ? 'contrast-light' : 'contrast-dark';
    } else {
      productContrast = colorLightness(courseTheme.productColor) === 0 ? 'contrast-light' : 'contrast-dark';
    }

    return (
      <div>
        <Helmet
          title="Home"
          titleTemplate={`%s | ${courseTitle} | Oxford University Press`}
        />
        <ThemeProvider themeOptions={courseTheme}>
          <div style={productCSSVariables({ ...themeOptions, ...courseTheme })}
              className={productContrast}>
            {children}
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

export default compose(
  // withLocalizedContent('ngsCourseLayout'),
  withTheme,
  connect(
    (state, { params }) => ({
      region: localStorage.getItem('region'),
      orgId: state.identity.currentOrganisationId,
      courseId: params.id,
      courseTitle: state.ngsCourse.course.title,
      courseTheme: state.ngsCourse.course.theme,
      loading: !state.ngsCourse.success,
      error: state.ngsCourse.error
    }),
    (dispatch) => ({
      loadCourse: (orgId, region, courseId) => {
        dispatch(loadCourse(orgId, region, courseId))
      },
      unloadCourse: () => {
        dispatch(unloadCourse())
      }
    })
  )
)(NGSCourseLayout);
