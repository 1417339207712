// Smart is currently unused, this file will need to be updated to react-router 5.x to work again.
import React from 'react';
import { Route, Router } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotFoundView from '../views/errors/NotFoundView';

// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';

// Dev pages

import SmartIndexRedirectHandler from '../routes/SmartIndexRedirectHandler';
import SmartRoot from './SmartRoot/SmartRoot';
import SmartDemoLoginPage from '../routes/development/Smart/SmartDemoLoginPage/SmartDemoLoginPage';
import SmartTeacherLayout from './SmartTeacherLayout/SmartTeacherLayout';
import SmartTeacherReportingAllClassesCurriculum from '../components/SmartTeacherReportingAllClassesCurriculum/SmartTeacherReportingAllClassesCurriculum';
import SmartTeacherReportingAllClassesTasks from '../components/SmartTeacherReportingAllClassesTasks/SmartTeacherReportingAllClassesTasks';
import SmartTeacherReportingAllClassesEngagement from '../components/SmartTeacherReportingAllClassesEngagement/SmartTeacherReportingAllClassesEngagement';
import SmartTeacherReportingGroupCurriculum from '../components/SmartTeacherReportingGroupCurriculum/SmartTeacherReportingGroupCurriculum';
import SmartTeacherReportingGroupTasks from '../components/SmartTeacherReportingGroupTasks/SmartTeacherReportingGroupTasks';
import SmartTeacherReportingGroupEngagement from '../components/SmartTeacherReportingGroupEngagement/SmartTeacherReportingGroupEngagement';
import SmartTeacherReportingStudentCurriculum from '../components/SmartTeacherReportingStudentCurriculum/SmartTeacherReportingStudentCurriculum';
import SmartTeacherReportingStudentTasks from '../components/SmartTeacherReportingStudentTasks/SmartTeacherReportingStudentTasks';
import SmartTeacherReportingStudentEngagement from '../components/SmartTeacherReportingStudentEngagement/SmartTeacherReportingStudentEngagement';
import SmartStudentLayout from './SmartStudentLayout/SmartStudentLayout';
import SmartStudentReportingTasks from '../components/SmartStudentReportingTasks/SmartStudentReportingTasks';
import SmartStudentReportingCourses from '../components/SmartStudentReportingCourses/SmartStudentReportingCourses';
import SmartDemoPage from '../components/SmartDemoPage/SmartDemoPage';
import DemoLanding from '../routes/DemoLanding/DemoLanding';
import SmartDemoQuad from '../components/SmartDemoQuad/SmartDemoQuad';
import StudentReportingTasks from '../pages/Smart/StudentReportingTasks/StudentReportingTasks';

function RouteManager({ history }) {
  return (
    <Router history={history}>
      {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
      <Route path="blank" component={BlankPage} />

      <Route path="/" component={DemoLanding} />
      {/* Smart Routes */}
      <Route path="smart" component={SmartIndexRedirectHandler} />
      <Route component={SmartRoot}>
        <Route path="smart-demo" component={SmartDemoLoginPage} />
      </Route>

      {/* Teacher reporting routes */}
      {/* NOTE - Routes are prefixed with /dev in order to bypass authentication. When we finish implementing auth0 /dev will be removed */}
      <Route component={SmartTeacherLayout}>
        <Route
          path="/dev/teacher-reporting/all-classes/curriculum"
          component={SmartTeacherReportingAllClassesCurriculum}
        />
        <Route path="/dev/teacher-reporting/all-classes/tasks" component={SmartTeacherReportingAllClassesTasks} />
        <Route
          path="/dev/teacher-reporting/all-classes/engagement"
          component={SmartTeacherReportingAllClassesEngagement}
        />
        <Route
          path="/dev/teacher-reporting/group/:groupId/curriculum"
          component={SmartTeacherReportingGroupCurriculum}
        />
        <Route path="/dev/teacher-reporting/group/:groupId/tasks" component={SmartTeacherReportingGroupTasks} />
        <Route
          path="/dev/teacher-reporting/group/:groupId/engagement"
          component={SmartTeacherReportingGroupEngagement}
        />
        <Route
          path="/dev/teacher-reporting/student/:studentId/curriculum"
          component={SmartTeacherReportingStudentCurriculum}
        />
        <Route path="/dev/teacher-reporting/student/:studentId/tasks" component={SmartTeacherReportingStudentTasks} />
        <Route
          path="/dev/teacher-reporting/student/:studentId/engagement"
          component={SmartTeacherReportingStudentEngagement}
        />
      </Route>

      {/* Student reporting routes */}
      {/* NOTE - Routes are prefixed with /dev in order to bypass authentication. When we finish implementing auth0 /dev will be removed */}
      <Route component={SmartStudentLayout}>
        <Route path="/dev/student-reporting/tasks/:studentId" component={SmartStudentReportingTasks} />
        <Route path="/dev/student-reporting/tasks-context/:studentId" component={StudentReportingTasks} />
        <Route path="/dev/student-reporting/courses/:studentId" component={SmartStudentReportingCourses} />
      </Route>

      <Route path="/dev/demo-sso" component={SmartDemoPage} />
      <Route path="/dev/demo-quad" component={SmartDemoQuad} />

      {/* 404 */}
      <Route path="*" component={NotFoundView} />
    </Router>
  );
}

RouteManager.propTypes = {
  history: PropTypes.object
};

export default RouteManager;
