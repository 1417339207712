import React from 'react';
import PropTypes from 'prop-types';
import SmartOverlayPanelAssessments from './SmartOverlayPanelTableAssessments/SmartOverlayPanelTableAssessments';
import SmartOverlayPanelTableAutoManuallyMarked from './SmartOverlayPanelTableAutoManuallyMarked/SmartOverlayPanelTableAutoManuallyMarked';
import SmartOverlayPanelTableLevelUp from './SmartOverlayPanelTableLevelUp/SmartOverlayPanelTableLevelUp';
import SmartOverlayPanelTableNonMarkedItem from './SmartOverlayPanelForNonMarked/SmartOverlayPanelTableNonMarkedItem';
// eslint-disable-next-line no-unused-vars
import SmartOverlayPanelTableSortAndFilter from './SmartOverlayPanelTableSortAndFilter/SmartOverlayPanelTableSortAndFilter';
import SmartOverlayPanelTableManuallyNonInteractive from './SmartOverlayPanelTableManuallyNonInteractive/SmartOverlayPanelTableManuallyNonInteractive';
import SmartOverlayPanelTableReactivates from './SmartOverlayPanelTableReactivates/SmartOverlayPanelTableReactivates';

const SmartOverlayPanelTableContainer = ({ isOpen, handleClose, animation, type, item }) => {
  const renderOverlay = () => {
    switch (type) {
      case 'assessment':
        return (
          <SmartOverlayPanelAssessments
            isOpen={isOpen}
            handleClose={handleClose}
            animation={animation}
            assessment={item}
          />
        );
      case 'autoMarked':
      case 'manualMarked':
        return (
          <SmartOverlayPanelTableAutoManuallyMarked
            isOpen={isOpen}
            handleClose={handleClose}
            animation={animation}
            autoManuallyMarkedItem={item}
          />
        );
      case 'levelup':
        return (
          <SmartOverlayPanelTableLevelUp
            isOpen={isOpen}
            handleClose={handleClose}
            animation={animation}
            levelup={item}
          />
        );
      case 'nonMarked':
        return (
          <SmartOverlayPanelTableNonMarkedItem
            isOpen={isOpen}
            handleClose={handleClose}
            animation={animation}
            nonMarkedItem={item}
          />
        );
      case 'manualMarkedNI':
        return (
          <SmartOverlayPanelTableManuallyNonInteractive
            isOpen={isOpen}
            handleClose={handleClose}
            animation={animation}
            manuallyMarkedNI={item}
          />
        );
      case 'sortAndFilter':
        return (
          <SmartOverlayPanelTableSortAndFilter
            isOpen={isOpen}
            handleClose={handleClose}
            animation={animation}
            filters={item}
          />
        );
      case 'reactivate':
        return (
          <SmartOverlayPanelTableReactivates
            isOpen={isOpen}
            handleClose={handleClose}
            animation={animation}
            reactivateItem={item}
          />
        );
      default:
        console.info('No type has been provided for the overlay.');
        return <></>;
    }
  };

  return <>{renderOverlay()}</>;
};

SmartOverlayPanelTableContainer.defaultProps = {
  animation: 'left'
};

SmartOverlayPanelTableContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  animation: PropTypes.oneOf(['left', 'right']),
  type: PropTypes.oneOf([
    'sortAndFilter',
    'assessment',
    'levelup',
    'autoMarked',
    'nonMarked',
    'manualMarked',
    'manualMarkedNI',
    'reactivate'
  ]),
  item: PropTypes.object
};

export default SmartOverlayPanelTableContainer;
