import React from 'react';
import PropTypes from 'prop-types';

const SmartTeacherReportingStudentCurriculum = ({ params }) => <div>Curriculum for student {params.studentId})</div>;

SmartTeacherReportingStudentCurriculum.propTypes = {
  params: PropTypes.shape({ studentId: PropTypes.string.isRequired })
};

export default SmartTeacherReportingStudentCurriculum;
