import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styles from './SmartBadge.scss';

const SmartBadge = ({ label }) => {
  const {
    smartBadgeContainer,
    smartBadgeContainerBadgeDeveloping,
    smartBadgeContainerBadgeExtending,
    smartBadgeContainerBadgeSecure,
    smartBadgeContainerBadge
  } = styles;

  const badgeClass = classnames({
    [`${smartBadgeContainerBadgeDeveloping}`]: label.toLowerCase() === 'developing',
    [`${smartBadgeContainerBadgeExtending}`]: label.toLowerCase() === 'extending',
    [`${smartBadgeContainerBadgeSecure}`]: label.toLowerCase() === 'secure',
    [`${smartBadgeContainerBadge}`]:
      label.toLowerCase() !== 'developing' && label.toLowerCase() !== 'secure' && label.toLowerCase() !== 'extending'
  });

  return (
    <div className={smartBadgeContainer}>
      <div className={badgeClass}>{label}</div>
    </div>
  );
};

SmartBadge.propTypes = {
  label: PropTypes.string
};

export default SmartBadge;
