/**
 * IMPORTANT - Smart content is served from iframes in kerboodle
 * ALSO IMPORTANT - Current implementation is a Proof Of Concept
 *                  Some of the logic can change when propper API calls are implemented
 *
 * This components acts like the controller for everything related to teacher reporting
 * There are two levels of tabs that work off each other
 *    - top level tabs - All Classes, Group and Student
 *    - second level tabs - Curriculum, Engagement and Tasks
 *
 * When the iframe is "called" the relevant tabs are highlighted based on the url ( see parsePathnames)
 *
 * Switching between tabs is done in two different ways
 *    - by clicking the tab       - router.push is called with the url being a combination of the
 *                                  selected top and second level tabs and the entity id
 *                                - this is done internally by the component
 *    - by clickling on a <Link>  - done from any sub-pages rendered
 */

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import SecondLevelTabsWrapper from './../../components/SecondLevelTabs/SecondLevelTabsWrapper';
import NavTabs from './../../components/NavTabs/NavTabs';
import { GLYPHS } from './../../components/SVGIcon/SVGIcon';
import mockData from './mocks.json';

const topTabs = [
  {
    id: 'all-classes',
    type: '',
    name: 'All Classes',
    entityId: null,
    details: {
      label: '', // not used
      icon: GLYPHS.CHEVRON_DOWN
    }
  },
  {
    id: 'group',
    type: 'Group',
    name: '',
    entityId: '',
    details: {
      label: 'More',
      icon: GLYPHS.CHEVRON_DOWN
    }
  },
  {
    id: 'student',
    type: 'Student Report',
    name: 'Anna Prior',
    entityId: '',
    details: {
      label: 'More',
      icon: GLYPHS.CHEVRON_DOWN
    }
  }
];

const secondTabs = [
  {
    label: 'Curriculum',
    key: 'curriculum'
  },
  {
    label: 'Tasks',
    key: 'tasks'
  },
  {
    label: 'Engagement',
    key: 'engagement'
  }
];

class SmartTeacherLayout extends React.Component {
  constructor(props) {
    super(props);
    const urlPayload = this.parsePathname(location.pathname);
    const initialState = {
      topLevelTabs: topTabs.map(tab => ({
        ...tab,
        entityId: tab.id === urlPayload.topLevelTabId ? urlPayload.entityId : tab.entityId,
        name: tab.id === urlPayload.topLevelTabId && urlPayload.entityId ? decodeURI(urlPayload.name) : tab.name
      })), // load the entity id and the name for the current tab from the url
      secondLevelTabs: secondTabs,
      topLevelExpandedTabId: ''
    };
    this.state = this.getStateFromUrlData(initialState, urlPayload);

    // pre-populate the tabs with relavant data
    let currentGroup = this.getFirstGroup();
    let currentStudent = this.getFirstStudentFromGroup(currentGroup.id);

    if (urlPayload.topLevelTabId === 'student') {
      currentStudent = this.getStudentById(urlPayload.entityId);
      currentGroup = this.getStudentGroupById(urlPayload.entityId);
    } else if (urlPayload.topLevelTabId === 'group') {
      currentGroup = this.getGroupById(urlPayload.entityId);
      currentStudent = this.getFirstStudentFromGroup(currentGroup.id);
    }

    const updateTopLevelTabByIdInState = (tabId, data) => {
      this.state.topLevelTabs = this.state.topLevelTabs.map(tab => (tab.id === tabId ? { ...tab, ...data } : tab));
    };
    updateTopLevelTabByIdInState('group', { entityId: currentGroup.id, name: currentGroup.name });
    updateTopLevelTabByIdInState('student', { entityId: currentStudent.id, name: currentStudent.name });
  }

  componentDidUpdate(prevProps) {
    // this handles tab "selection" from <Link> navigation
    if (this.props.location !== prevProps.location) this.onLocationChanged();
  }

  onLocationChanged = () => {
    // handles tab highlighting when navigation is done via an <Link>
    const urlPayload = this.parsePathname(location.pathname);

    this.setState(state => this.getStateFromUrlData(state, urlPayload));

    const currentStudent =
      urlPayload.topLevelTabId === 'student'
        ? this.getStudentById(urlPayload.entityId)
        : this.getStudentById(this.getTopLevelTabById('student').entityId);

    const currentGroup =
      urlPayload.topLevelTabId === 'group'
        ? this.getGroupById(urlPayload.entityId)
        : this.getGroupById(this.getTopLevelTabById('group').entityId);

    if (urlPayload.topLevelTabId === 'group' && currentStudent.group_id !== currentGroup.id) {
      const firstStudent = this.getFirstStudentFromGroup(currentGroup.id);
      this.updateTopLevelTabDataInState('student', { entityId: firstStudent.id, name: firstStudent.name });
    } else if (urlPayload.topLevelTabId === 'student' && currentStudent.group_id !== currentGroup.id) {
      const studentGroup = this.getGroupById(currentStudent.id);
      this.updateTopLevelTabDataInState('group', { entityId: studentGroup.id, name: studentGroup.name });
    }
  };

  onTopLevelTabsClick = tabId => {
    this.setState(
      state => ({
        ...state,
        topLevelActiveTabId: tabId,
        // the onTopLevelTabsDetailClick is called before this is called when the user clicks directly on "More"
        // onTopLevelTabsDetailClick sets the current tab id and the current expanded tab id
        // here we just make sure that we keep the proper tab expanded
        topLevelExpandedTabId: state.topLevelExpandedTabId === tabId ? state.topLevelExpandedTabId : ''
      }),
      this.redirectToTabGeneratedUrl
    );
  };

  onSecondLevelTabsClick = tabId => {
    this.setState(state => ({ ...state, secondLevelActiveTabId: tabId }), this.redirectToTabGeneratedUrl);
  };

  onTopLevelTabsDetailClick = (tabId, isExpanded) => {
    this.setState(
      state => ({ ...state, topLevelActiveTabId: tabId, topLevelExpandedTabId: isExpanded ? '' : tabId }),
      this.redirectToTabGeneratedUrl
    );
  };

  getStateFromUrlData = (prevState, urlPayload) => {
    let currentTabEntityName = '';
    switch (urlPayload.topLevelTabId) {
      case 'group':
        currentTabEntityName = this.getGroupById(urlPayload.entityId).name;
        break;
      case 'student':
        currentTabEntityName = this.getStudentById(urlPayload.entityId).name;
        break;
      case 'all-classes':
      default:
        currentTabEntityName = '';
    }

    return {
      ...prevState,
      topLevelActiveTabId: urlPayload.topLevelTabId,
      secondLevelActiveTabId: urlPayload.secondLevelTabId,
      topLevelTabs: prevState.topLevelTabs.map(tab => ({
        ...tab,
        entityId: tab.id === urlPayload.topLevelTabId ? urlPayload.entityId : tab.entityId,
        name: tab.id === urlPayload.topLevelTabId && urlPayload.entityId ? currentTabEntityName : tab.name
      }))
    };
  };

  // #region Mock data utility methods
  // these methods can be removed when implementing actual API calls
  getFirstGroup = () => mockData.groups[0];
  getGroupById = groupId => mockData.groups.find(g => g.id === groupId);
  getFirstStudentFromGroup = groupId => mockData.students.find(s => s.group_id === groupId);
  getStudentById = studentId => mockData.students.find(s => s.id === studentId);
  getStudentGroupById = studentId => this.getGroupById(this.getStudentById(studentId).group_id);
  getTopLevelTabById = tabId => this.state.topLevelTabs.find(t => t.id === tabId);

  // #endregion
  parsePathname = (pathname = '') => {
    const regexForPathnameWithEntityId = /^\/dev\/teacher-reporting\/(?<topLevelTabId>[^/]+)\/(?<entityId>[^/]+)\/(?<secondLevelTabId>[^/]+)$/;
    const regexForPathnameWithoutEntityId = /^\/dev\/teacher-reporting\/(?<topLevelTabId>[^/]+)\/(?<secondLevelTabId>[^/]+)$/;

    let result = { topLevelTabId: '', secondLevelTabId: '', entityId: '' };

    if (regexForPathnameWithEntityId.test(pathname))
      result = { ...result, ...pathname.match(regexForPathnameWithEntityId).groups };
    else if (regexForPathnameWithoutEntityId.test(pathname))
      result = { ...result, ...pathname.match(regexForPathnameWithoutEntityId).groups };
    else throw Error(`Pathname ${pathname} not supported`);

    return result;
  };

  updateTopLevelTabDataInState = (tabId, data) =>
    this.setState(state => ({
      ...state,
      topLevelTabs: state.topLevelTabs.map(tab => (tab.id === tabId ? { ...tab, ...data } : tab))
    }));

  redirectToTabGeneratedUrl = () => {
    if (this.state.topLevelActiveTabId === '' || this.state.secondLevelActiveTabId === '') return;

    const selectedTopTab = this.state.topLevelTabs.find(t => t.id === this.state.topLevelActiveTabId);
    const url = selectedTopTab.entityId
      ? `/dev/teacher-reporting/${this.state.topLevelActiveTabId}/${selectedTopTab.entityId}/${this.state.secondLevelActiveTabId}`
      : `/dev/teacher-reporting/${this.state.topLevelActiveTabId}/${this.state.secondLevelActiveTabId}`;

    if (url !== location.pathname) this.props.router.push(url);
  };

  renderGroupList = () =>
    mockData.groups.map(group => (
      <div key={group.id}>
        <Link to={`/dev/teacher-reporting/group/${group.id}/${this.state.secondLevelActiveTabId}`}>{group.name}</Link>
      </div>
    ));

  renderStudentList = () =>
    mockData.students
      .filter(student => student.group_id === this.getTopLevelTabById('group').entityId)
      .map(student => (
        <div key={student.id}>
          <Link to={`/dev/teacher-reporting/student/${student.id}/${this.state.secondLevelActiveTabId}`}>
            {student.name}
          </Link>
        </div>
      ));

  render() {
    return (
      <div>
        <NavTabs
          items={this.state.topLevelTabs}
          activeItemKey={this.state.topLevelActiveTabId}
          expandedItemKey={this.state.topLevelExpandedTabId}
          onSelectedTab={this.onTopLevelTabsClick}
          onClickDetails={this.onTopLevelTabsDetailClick}
        />

        {this.state.topLevelExpandedTabId === 'group' && (
          <div>
            <h3>Groups list</h3>
            {this.renderGroupList()}
          </div>
        )}

        {this.state.topLevelExpandedTabId === 'student' && (
          <div>
            <h3>Student list</h3>
            {this.renderStudentList()}
          </div>
        )}

        <SecondLevelTabsWrapper
          items={this.state.secondLevelTabs}
          activeItemKey={this.state.secondLevelActiveTabId}
          handleClickItem={this.onSecondLevelTabsClick}
        />
        {this.props.children}
      </div>
    );
  }
}

SmartTeacherLayout.propTypes = {
  children: PropTypes.any,
  router: PropTypes.any,
  location: PropTypes.object.isRequired
};

export default withRouter(SmartTeacherLayout);
